/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import { Container, Navbar, Nav, NavItem } from "react-bootstrap";

import ctLogo from "../assets/img/new_logo.png";
import reactLogo from "../assets/img/reactlogo.png";
import image from "../assets/img/full-image-1.jpg";

const headerBackground = {
  backgroundImage: "url(" + image + ")",
};

function Header() {
  return (
    <div className="header-wrapper">
      <Navbar
        collapseOnSelect
        className="navbar-transparent navbar-components position-absolute bg-transparent w-100 p-4"
      >
        <Container className="d-flex justify-content-between">
          <Navbar.Brand as="div" className="p-0">
            <div className="navbar-title text-center p-0">
              <h4 className="m-0">
                <div className="image-container h-auto">
                  <img alt="React Logo" src={reactLogo} />
                </div>
                LBD PRO React
              </h4>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  className="nav-link text-white"
                  href="https://github.com/creativetimofficial/ct-light-bootstrap-dashboard-pro-react/issues"
                  target="_blank"
                >
                  <p>Have an issue?</p>
                </a>
              </li>
              <li>
                <NavLink
                  to="/"
                  className="nav-link text-white"
                  activeClassName="active"
                >
                  <p>Go back to dashboard</p>
                </NavLink>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="header" style={headerBackground}>
        <div className="filter" />
        <div className="title-container text-center">
          <div className="image-header">
            <img src={reactLogo} alt="React Logo" />
          </div>
          <h1>LBD Pro React</h1>
        </div>
      </div>
    </div>
  );
}

export default Header;

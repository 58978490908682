/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const codeImport = `import { DropdownButton, Dropdown } from 'react-bootstrap';`;
const codeExample = `<DropdownButton title="Default" key="1" id={\`dropdown-basic-1\`}>
    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item eventKey="5">One more separated link</Dropdown.Item>
</DropdownButton>`;

const options = {
  mode: "jsx",
  readOnly: true,
};

class DropdownSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Dropdown</h2>
          <legend />
          <p>
            We are very proud to present the dropdown, we added a subtle
            animation for this classic widget.
          </p>
          <p>Here is an examples and the code:</p>
          <Row>
            <Col md={3} className="dropdown">
              <DropdownButton title="Default" key="1" id={`dropdown-basic-1`}>
                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="5">
                  One more separated link
                </Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>
          <br />
          <p>
            To use the dropdown from react-bootstrap you need to import it with
            it's elements like so:
          </p>
          <CodeMirror value={codeImport} options={options} />
          <p>And here is the code:</p>
          <CodeMirror value={codeExample} options={options} />
          <p>
            For more info please refer to{" "}
            <a
              href="https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/dropdowns/#btn-dropdowns"
              target="_blank"
            >
              react-bootstrap documentation
            </a>
            .
          </p>
          <h4>Props</h4>
          <p>
            Please refer to{" "}
            <a
              href="https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/dropdowns/#btn-dropdowns-props"
              target="_blank"
            >
              react-bootstrap documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default DropdownSection;

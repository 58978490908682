/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

class WizardSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>React Bootstrap Wizard v0.0.9</h2>
          <legend />
          <p>
            If you have lengthy forms to complete, you can use
            react-bootstrap-wizard. This plugin allows you to cut the form into
            steps and let the user complete it gradually.
          </p>
          <p>
            We have changed the typography, inputs, buttons and we have added
            validation for inputs. You can see how it looks and feels in the
            live demo. For more information please check{" "}
            <a
              href="https://github.com/creativetimofficial/react-bootstrap-wizard"
              target="_blank"
            >
              Full Github Documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default WizardSection;

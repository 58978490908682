/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";
import { VectorMap } from "react-jvectormap";

const options = {
  mode: "jsx",
  readOnly: true,
};
const exampleCode = `import React from 'react';
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};

function VectorMaps({...props}){
  return (
    <VectorMap
      map={"world_mill"}
      backgroundColor="transparent"
      zoomOnScroll={false}
      containerStyle={{
        width: "100%",
        height: "420px"
      }}
      containerClassName="map"
      regionStyle={{
        initial: {
          fill: "#e4e4e4",
          "fill-opacity": 0.9,
          stroke: "none",
          "stroke-width": 0,
          "stroke-opacity": 0
        }
      }}
      series={{
        regions: [
          {
            values: mapData,
            scale: ["#AAAAAA", "#444444"],
            normalizeFunction: "polynomial"
          }
        ]
      }}
    />
  );
}

export default VectorMaps;`;

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};
class VectorMapsSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>React jVectormap v0.0.16</h2>
          <legend />
          <p>
            For this component we've used <code>react-jvectormap</code> library.
            We recommend using them for statistics for countries.
          </p>
          <h4>Example Code</h4>
          <VectorMap
            map={"world_mill"}
            backgroundColor="transparent"
            zoomOnScroll={false}
            containerStyle={{
              width: "100%",
              height: "280px",
            }}
            containerClassName="map"
            regionStyle={{
              initial: {
                fill: "#e4e4e4",
                "fill-opacity": 0.9,
                stroke: "none",
                "stroke-width": 0,
                "stroke-opacity": 0,
              },
            }}
            series={{
              regions: [
                {
                  values: mapData,
                  scale: ["#AAAAAA", "#444444"],
                  normalizeFunction: "polynomial",
                },
              ],
            }}
          />
          <CodeMirror value={exampleCode} options={options} />
          <br />
          <h4>Props</h4>
          <p>
            For details please refer to{" "}
            <a
              href="https://github.com/kadoshms/react-jvectormap"
              target="_blank"
            >
              react-jvectormap official documentation
            </a>{" "}
            and/or{" "}
            <a href="http://jvectormap.com/documentation/" target="_blank">
              jvectormap official documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default VectorMapsSection;

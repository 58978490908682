/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};
const importCode = `import Sidebar from 'components/Sidebar/Sidebar.js';`;
const exampleImageImport = `import image from 'assets/img/full-screen-image-3.jpg';
const bgImage = {backgroundImage: "url("+image+""};`;
const exampleImageCode = `<div className="sidebar-background" style={bgImage}></div>
    <div className="sidebar" data-color="azure" data-image={image}>
        ...
    </div>
</div>`;
class SidebarSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Sidebar</h2>
          <legend />
          <p>
            We have created a component for the main navigation of this
            dashboard, <code>Sidebar</code>, which is located in{" "}
            <code>src/components/Sidebar/Sidebar.js</code>. It contains the
            company title, a photo of the logged in user with options inside a
            dropdown, and a list of all the pages. Every element in the list of
            pages can have a sublist of pages. .
          </p>
          <h4>Imports</h4>
          <p>To use this component you'll have to make this import:</p>
          <CodeMirror value={importCode} options={options} />
          <h4>Example Code</h4>
          <p>
            As we were saying in <code>Navigation</code> component, here we
            import again the <code>HeaderLinks</code> (links that appear in{" "}
            <code>Navbar</code>), so if we are on responsive mode, we show them
            as well in our <code>Sidebar</code>.
          </p>
          <p>
            You can navigate to <code>src/components/Sidebar/Sidebar.js</code>{" "}
            and understand better.
          </p>
          <p>
            If you want the <code>Sidebar</code> component to have the
            background as a solid color, you can use the <code>data-color</code>{" "}
            attribute on the wrapper <code>div</code> and add the color you
            like:{" "}
            <code>
              data-color="blue | azure | green | orange | red | purple"
            </code>
            .
          </p>
          <p>
            You can also add an image to your <code>Sidebar</code> by using{" "}
            <code>data-image</code> attribute on the wrapper <code>div</code>,
            and than you will have to make a <code>div</code> with an inline
            style with the <code>backgroundImage</code> set to your image.
          </p>
          <p>
            This is an example of how these two <code>div</code>s should look in
            the render method:
          </p>
          <CodeMirror value={exampleImageCode} options={options} />
          <p>
            And before this, you need to import the image and set a variable
            with <code>backgroundImage</code> style like this:
          </p>
          <CodeMirror value={exampleImageImport} options={options} />
          <p>
            This component is tied up with the <code>routes</code> of the
            dashboard. These are found in <code>src/routes.js</code> and are
            rendered in the <code>Admin</code> and <code>Auth</code> layouts (
            <code>src/layouts/Admin.js</code>, <code>src/layouts/Auth.js</code>
            ).
          </p>
        </div>
      </div>
    );
  }
}

export default SidebarSection;

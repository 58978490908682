/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

function RTLSupportSection({ ...props }) {
  return (
    <div className="tim-container">
      <h2>RTL Support</h2>
      <legend />
      <p>
        For this please refer to{" "}
        {
          // eslint-disable-next-line
        }
        <a
          href="https://github.com/creativetimofficial/ct-light-bootstrap-dashboard-pro-react/issues/5"
          target="_blank"
        >
          this issue here
        </a>
        .
      </p>
    </div>
  );
}

export default RTLSupportSection;

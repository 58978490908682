/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// plugin that creates slider
import Slider from "nouislider";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};

const codeExample = `import React, { Component } from "react";
// plugin that creates slider
import Slider from "nouislider";

function SlidersSection (){
  const slider1Ref = React.useRef(null);
  const slider2Ref = React.useRef(null);
  React.useEffect(() => {
    var slider1 = slider1Ref.current;
    var slider2 = slider2Ref.current;
    if (slider1.className === "slider") {
      Slider.create(slider1, {
        start: [40],
        connect: [true, false],
        step: 1,
        range: { min: 0, max: 100 },
      });
    }
    if (slider2.className === "slider") {
      Slider.create(slider2, {
        start: [20, 60],
        connect: [false, true, false],
        step: 1,
        range: { min: 0, max: 100 },
      });
    }
  }, []);
  return (
    <>
      <div className="slider" id="slider1" ref={slider1Ref}/>
      <br />
      <div className="slider" id="slider2" ref={slider2Ref}/>
      <br />
    </>
  );
}

export default SlidersSection;
`;

function SlidersSection() {
  const slider1Ref = React.useRef(null);
  const slider2Ref = React.useRef(null);
  React.useEffect(() => {
    var slider1 = slider1Ref.current;
    var slider2 = slider2Ref.current;
    if (slider1.className === "slider") {
      Slider.create(slider1, {
        start: [40],
        connect: [true, false],
        step: 1,
        range: { min: 0, max: 100 },
      });
    }
    if (slider2.className === "slider") {
      Slider.create(slider2, {
        start: [20, 60],
        connect: [false, true, false],
        step: 1,
        range: { min: 0, max: 100 },
      });
    }
  }, []);
  return (
    <div className="tim-container">
      <div className="tim-row">
        <h2>Nouislider v14.6.3</h2>
        <legend />
        <p>We restyled nouislider, while keeping the design consistent.</p>
        <h4>Example code</h4>
        <div className="slider" id="slider1" ref={slider1Ref} />
        <br />
        <div className="slider" id="slider2" ref={slider2Ref} />
        <br />
        <CodeMirror value={codeExample} options={options} />
        <br />
        <p>
          We've added some special colors to these components. To use our colors
          on the <br />
          <code>{`<div id="slider1" />`}</code>, for example, add{" "}
          <code>{`className="slider-*"`}</code>, where <code>*</code> can be one
          of <code>primary</code>,<code>info</code>,<code>success</code>,
          <code>warning</code> or <code>danger</code>.
        </p>
        <h4>Props</h4>
        <p>
          Please refer to{" "}
          <a href="https://refreshless.com/nouislider/" target="_blank">
            nouislider documentation
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default SlidersSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/styles/prism";
import { Alert } from "react-bootstrap";

class RoutingSystem extends React.Component {
  render() {
    return (
      <>
        <div>
          <h1 className="bd-title" id="content">
            Routing System
          </h1>
          <div className="avatar-group mt-3" />
        </div>
        <p className="bd-lead">
          We've created these dynamic routes, so we wouldn't have to write them
          in two places. On place would have been inside our{" "}
          <Link to="/documentation/sidebar">Sidebar</Link> and the onther one
          would be either the{" "}
          <code className="highlighter-rouge">src/layouts/Admin.js</code> layout
          or the <code className="highlighter-rouge">src/Admin/Auth.js</code>{" "}
          layout. You will find all our demo routes in{" "}
          <code className="highlighter-rouge">src/routes.js</code>
        </p>
        <hr />
        <Alert color="warning">
          Please note that these are just demo routes. You can delete them and
          create your own routing system, or you can easily replace them with
          normal{" "}
          <a
            href="https://reacttraining.com/react-router/web/api/Route?ref=creativetim"
            target="_blank"
          >
            Routes
          </a>{" "}
          and{" "}
          <a
            href="https://reacttraining.com/react-router/web/api/Link?ref=creativetim"
            target="_blank"
          >
            Links
          </a>{" "}
          or{" "}
          <a
            href="https://reacttraining.com/react-router/web/api/NavLink?ref=creativetim"
            target="_blank"
          >
            NavLinks
          </a>{" "}
          from{" "}
          <a
            href="https://reacttraining.com/react-router/web/example/basic?ref=creativetim"
            target="_blank"
          >
            react-router-dom
          </a>
          .
          <br />
          If you do not understand them, please take in consideration replacing
          them with normal routes and use the <b>src/routes.js</b> just for
          rendering Links inside the{" "}
          <Link to="/documentation/sidebar">Sidebar</Link> component.
        </Alert>
        <h2 id="content">Legend</h2>
        <ol>
          <li>
            <SyntaxHighlighter language="jsx" style={prism}>
              {`{path: "/path-name", name: "Name Of the View", icon: "icon name", component: ViewComponent, layout: "/layouth-path"},`}
            </SyntaxHighlighter>
            <ul>
              <li>
                <code className="highlighter-rouge">path</code> (path for your
                route - this will be seen in the browser url input - example{" "}
                <code className="highlighter-rouge">/dashboard</code>)
              </li>
              <li>
                <code className="highlighter-rouge">name</code> (name of your
                route - this will appear in the{" "}
                <code className="highlighter-rouge">Sidebar</code> and{" "}
                <code className="highlighter-rouge">Header</code> components)
              </li>
              <li>
                <code className="highlighter-rouge">icon</code> (icon to be
                displayed alongside with links in{" "}
                <code className="highlighter-rouge">Sidebar</code> component -
                example <code className="highlighter-rouge">fa fa-heart</code>)
              </li>
              <li>
                <code className="highlighter-rouge">component</code> (this is
                the View component that you want to be displayed on the
                specified route - example{" "}
                <code className="highlighter-rouge">Dashboard</code>)
              </li>
              <li>
                <code className="highlighter-rouge">layout</code> (path of the
                layout in which the View component you want to be rendered - in
                our template demo you only have to options:{" "}
                <code className="highlighter-rouge">/admin</code> and{" "}
                <code className="highlighter-rouge">/auth</code> - but due to
                this routing system you can add more, for example{" "}
                <code className="highlighter-rouge">/new-layout</code>)
              </li>
            </ul>
          </li>
          <li>
            <SyntaxHighlighter language="jsx" style={prism}>
              {`{ collapse: true, name: "Name of the collapse group", icon: "Icon of the collapse group", state: "string", views: [arrayOfRoutes]},`}
            </SyntaxHighlighter>
            <ul>
              <li>
                <code className="highlighter-rouge">collapse</code> (used to
                tell our deom app components this is a collapsible group - for{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>
                ,
                <code className="highlighter-rouge">
                  src/layouts/Admin/Admin.js
                </code>{" "}
                and{" "}
                <code className="highlighter-rouge">
                  src/layouts/Auth/Auth.js
                </code>{" "}
                - you can only use it like so:{" "}
                <code className="highlighter-rouge">collapse: true</code>)
              </li>
              <li>
                <code className="highlighter-rouge">name</code> (name of
                collapsible group that is displayed in{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>{" "}
                - example <code className="highlighter-rouge">Forms</code>)
              </li>
              <li>
                <code className="highlighter-rouge">state</code> (name of the
                state used in{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>
                's state to know which collapsible is active/collapsed - based
                on these the state of the{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>{" "}
                is created - exmaple{" "}
                <code className="highlighter-rouge">pagesCollapse</code>)
              </li>
              <li>
                <code className="highlighter-rouge">icon</code> (icon to be
                displayed alonside the name of the collapsible group - example{" "}
                <code className="highlighter-rouge">fa fa-heart</code>)
              </li>
              <li>
                <code className="highlighter-rouge">views</code> (array of links
                that will be part of the collapsible group)
                <br />
                <code className="highlighter-rouge">arrayOfRoutes</code>
                <ol>
                  <li>
                    <SyntaxHighlighter language="jsx" style={prism}>
                      {`{path: "/path-name", name: "Name Of the View", icon: "icon name", component: ViewComponent, layout: "/layouth-path"},`}
                    </SyntaxHighlighter>
                  </li>
                  <li>
                    <SyntaxHighlighter language="jsx" style={prism}>
                      {`{ collapse: true, name: "Name of the collapse group", state: "string", views: [arrayOfRoutes]},`}
                    </SyntaxHighlighter>
                  </li>
                </ol>
              </li>
            </ul>
          </li>
        </ol>
        <p>
          For a better understanding, please take a look inside the file at
          hand, and also how the routes are rendered while the app si opened.
        </p>
        <h2>
          <code className="highlighter-rouge">Notice</code>
        </h2>
        <p>
          Because our routes are arrays of objects, and each route is an object,
          you can add what props you want in our routes and do what you want
          with them.
        </p>
        <p>
          For example, if you want to "hide" a route (you want it to not be
          displayed in sidebar), you could add a prop like{" "}
          <code className="highlighter-rouge">invisible: true</code> and then in
          sidebar add an if statement inside the{" "}
          <code className="highlighter-rouge">map</code> function of ours and do
          like this:
        </p>
        <SyntaxHighlighter language="jsx" style={prism}>
          {`if(prop.invisible) return null;`}
        </SyntaxHighlighter>
      </>
    );
  }
}

export default RoutingSystem;

import TutorialSection from "layouts/Components/Rows/TutorialSection.js";
import RTLSupportSection from "layouts/Components/Rows/RTLSupportSection.js";
import RoutingSystemSection from "layouts/Components/Rows/RoutingSystemSection.js";
import ButtonsSection from "layouts/Components/Rows/ButtonsSection.js";
import CardsSection from "layouts/Components/Rows/CardsSection.js";
import ChartsSection from "layouts/Components/Rows/ChartsSection.js";
import CheckboxRadioSwitchSection from "layouts/Components/Rows/CheckboxRadioSwitchSection.js";
import CustomizableSelectSection from "layouts/Components/Rows/CustomizableSelectSection.js";
import DateTimePickerSection from "layouts/Components/Rows/DateTimePickerSection.js";
import DropdownSection from "layouts/Components/Rows/DropdownSection.js";
import FootersSection from "layouts/Components/Rows/FooterSection.js";
import GoogleMapsSection from "layouts/Components/Rows/GoogleMapsSection.js";
import IconsSection from "layouts/Components/Rows/IconsSection.js";
import InputsSection from "layouts/Components/Rows/InputsSection.js";
import NavigationSection from "layouts/Components/Rows/NavigationSection.js";
import NotificationSection from "layouts/Components/Rows/NotificationSection.js";
import PaginationSection from "layouts/Components/Rows/PaginationSection.js";
import PanelsSection from "layouts/Components/Rows/PanelsSection.js";
import PerfectScrollbarSection from "layouts/Components/Rows/PerfectScrollbarSection.js";
import ProgressBarsSection from "layouts/Components/Rows/ProgressBarsSection.js";
import ReactTableSection from "layouts/Components/Rows/ReactTableSection.js";
import SidebarSection from "layouts/Components/Rows/SidebarSection.js";
import SlidersSection from "layouts/Components/Rows/SlidersSection.js";
import SweetAlertSection from "layouts/Components/Rows/SweetAlertSection.js";
import TablesSection from "layouts/Components/Rows/TablesSection.js";
import TabsSection from "layouts/Components/Rows/TabsSection.js";
import TagsSection from "layouts/Components/Rows/TagsSection.js";
import TasksSection from "layouts/Components/Rows/TasksSection.js";
import TextareaSection from "layouts/Components/Rows/TextareaSection.js";
import TooltipSection from "layouts/Components/Rows/TooltipSection.js";
import VectorMapsSection from "layouts/Components/Rows/VectorMapsSection.js";
import WizardSection from "layouts/Components/Rows/WizardSection.js";

var componentsRoutes = [
  {
    path: "/documentation/tutorial",
    component: TutorialSection,
    name: "Tutoiral"
  },
  {
    path: "/documentation/rtl",
    component: RTLSupportSection,
    name: "RTL Support"
  },
  {
    path: "/documentation/routing-system",
    component: RoutingSystemSection,
    name: "Routing System"
  },
  {
    path: "/documentation/buttons",
    component: ButtonsSection,
    name: "Buttons"
  },
  { path: "/documentation/cards", component: CardsSection, name: "Cards" },
  { path: "/documentation/charts", component: ChartsSection, name: "Charts" },
  {
    path: "/documentation/checkbox-radio-switch",
    component: CheckboxRadioSwitchSection,
    name: "Checkbox\\Radio\\Switch"
  },
  {
    path: "/documentation/customizable-select",
    component: CustomizableSelectSection,
    name: "Customizable Select"
  },
  {
    path: "/documentation/date-time-picker",
    component: DateTimePickerSection,
    name: "DateTimePicker"
  },
  {
    path: "/documentation/dropdown",
    component: DropdownSection,
    name: "Dropdown"
  },
  {
    path: "/documentation/footers",
    component: FootersSection,
    name: "Footers"
  },
  {
    path: "/documentation/google-maps",
    component: GoogleMapsSection,
    name: "Google Maps"
  },
  { path: "/documentation/icons", component: IconsSection, name: "Icons" },
  { path: "/documentation/inputs", component: InputsSection, name: "Inputs" },
  {
    path: "/documentation/navigation",
    component: NavigationSection,
    name: "Navigation"
  },
  {
    path: "/documentation/notifications",
    component: NotificationSection,
    name: "Notification"
  },
  {
    path: "/documentation/pagination",
    component: PaginationSection,
    name: "Pagination"
  },
  { path: "/documentation/panels", component: PanelsSection, name: "Panels" },
  {
    path: "/documentation/perfect-scrollbar",
    component: PerfectScrollbarSection,
    name: "Perfect Scrollbar"
  },
  {
    path: "/documentation/progress-bars",
    component: ProgressBarsSection,
    name: "Progress Bars"
  },
  {
    path: "/documentation/react-table",
    component: ReactTableSection,
    name: "React Table"
  },
  {
    path: "/documentation/sidebar",
    component: SidebarSection,
    name: "Sidebar"
  },
  {
    path: "/documentation/sliders",
    component: SlidersSection,
    name: "Sliders"
  },
  {
    path: "/documentation/sweet-alert",
    component: SweetAlertSection,
    name: "Sweet Alert"
  },
  { path: "/documentation/tables", component: TablesSection, name: "Tables" },
  { path: "/documentation/tabs", component: TabsSection, name: "Tabs" },
  { path: "/documentation/tags", component: TagsSection, name: "Tags" },
  { path: "/documentation/tasks", component: TasksSection, name: "Tasks" },
  {
    path: "/documentation/textarea",
    component: TextareaSection,
    name: "Textarea"
  },
  {
    path: "/documentation/tooltips",
    component: TooltipSection,
    name: "Tooltip"
  },
  {
    path: "/documentation/vector-maps",
    component: VectorMapsSection,
    name: "Vector Maps"
  },
  { path: "/documentation/wizard", component: WizardSection, name: "Wizard" },
  { redirect: true, path: "/documentation", pathTo: "/documentation/tutorial" }
];

export default componentsRoutes;

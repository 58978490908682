/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};

const checkboxesCode = `import React from "react";

// react-bootstrap components
import {
  Form,
  Row,
  Col,
} from "react-bootstrap";

function Example() {
  return (
    <>
      <Row>
        <Col
          className="checkbox-radios"
          sm={{ span: 4, offset: 1 }}
        >
          <Form.Check>
            <Form.Check.Label>
              <Form.Check.Input type="checkbox"></Form.Check.Input>
              <span className="form-check-sign"></span>
              Unchecked
            </Form.Check.Label>
          </Form.Check>
          <Form.Check>
            <Form.Check.Label>
              <Form.Check.Input
                defaultChecked
                type="checkbox"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Checked
            </Form.Check.Label>
          </Form.Check>
          <Form.Check disabled>
            <Form.Check.Label>
              <Form.Check.Input
                disabled
                type="checkbox"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Disabled Unchecked
            </Form.Check.Label>
          </Form.Check>
          <Form.Check disabled>
            <Form.Check.Label>
              <Form.Check.Input
                disabled
                type="checkbox"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Disabled Checked
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </>
  );
}

export default Example;
`;
const radioButtonsCode = `import React from "react";

// react-bootstrap components
import {
  Form,
  Row,
  Col,
} from "react-bootstrap";

function Example() {
  return (
    <>
      <Row>
        <Col className="checkbox-radios" sm="5">
          <Form.Check className="form-check-radio">
            <Form.Check.Label>
              <Form.Check.Input
                defaultValue="option1"
                id="exampleRadios21"
                name="exampleRadio"
                type="radio"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Radio is off
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="form-check-radio">
            <Form.Check.Label>
              <Form.Check.Input
                defaultChecked
                defaultValue="option2"
                id="exampleRadios22"
                name="exampleRadio"
                type="radio"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Radio is on
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="form-check-radio" disabled>
            <Form.Check.Label>
              <Form.Check.Input
                defaultValue="option1"
                disabled
                id="exampleRadios31"
                name="exampleRadio1"
                type="radio"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Radio disabled is off
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="form-check-radio" disabled>
            <Form.Check.Label>
              <Form.Check.Input
                defaultChecked
                defaultValue="option2"
                disabled
                id="exampleRadios32"
                name="exampleRadio1"
                type="radio"
              ></Form.Check.Input>
              <span className="form-check-sign"></span>
              Radio disabled is on
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </>
  );
}

export default Example;
`;
const switchesCode = `import React from "react";

// react-bootstrap components
import {
  Form,
  Row,
  Col,
} from "react-bootstrap";

function Example() {
  return (
    <>
      <Row>
        <Col md="4">
          <p className="category">Default</p>
          <Form.Check
            type="switch"
            id="custom-switch-11"
            className="mb-1"
          />
          <Form.Check
            type="switch"
            id="custom-switch-12"
            defaultChecked
          />
        </Col>
        <Col md="4">
          <p className="category">Disabled</p>
          <Form.Check
            disabled
            type="switch"
            id="custom-switch-21"
            className="mb-1"
          />
          <Form.Check
            disabled
            type="switch"
            id="custom-switch-22"
            defaultChecked
          />
          <span className="toggle"></span>
        </Col>
        <Col md="4">
          <p className="category">With Labels</p>
          <label className="d-flex align-items-center">
            <Form.Check type="switch" id="custom-switch-31" />
            <span className="ml-1">Switch me</span>
          </label>
          <label className="d-flex align-items-center">
            <Form.Check
              type="switch"
              id="custom-switch-32"
              defaultChecked
            />
            <span className="ml-1">Switch me</span>
          </label>
        </Col>
      </Row>
    </>
  );
}

export default Example;
`;

function CheckboxRadioSwitchSection() {
  return (
    <div className="tim-container">
      <div className="tim-row">
        <h2>Checkboxes</h2>
        <legend />
        <h4>Example code</h4>
        <Row>
          <Col className="checkbox-radios" sm="4">
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input type="checkbox"></Form.Check.Input>
                <span className="form-check-sign"></span>
                Unchecked
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultChecked
                  type="checkbox"
                ></Form.Check.Input>
                <span className="form-check-sign"></span>
                Checked
              </Form.Check.Label>
            </Form.Check>
            <Form.Check disabled>
              <Form.Check.Label>
                <Form.Check.Input disabled type="checkbox"></Form.Check.Input>
                <span className="form-check-sign"></span>
                Disabled Unchecked
              </Form.Check.Label>
            </Form.Check>
            <Form.Check disabled>
              <Form.Check.Label>
                <Form.Check.Input disabled type="checkbox"></Form.Check.Input>
                <span className="form-check-sign"></span>
                Disabled Checked
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <CodeMirror value={checkboxesCode} options={options} />
        <p>
          Please refer to{" "}
          <a
            href="https://react-bootstrap.github.io/components/forms/#form-check-props"
            target="_blank"
          >
            react-bootstrap documentation
          </a>{" "}
          for more.
        </p>
      </div>
      <div className="tim-row">
        <h2>Radio Buttons</h2>
        <legend />
        <h4>Example code</h4>
        <Row>
          <Col className="checkbox-radios" sm="5">
            <Form.Check className="form-check-radio">
              <Form.Check.Label>
                <Form.Check.Input
                  defaultValue="option1"
                  id="exampleRadios21"
                  name="exampleRadio"
                  type="radio"
                ></Form.Check.Input>
                <span className="form-check-sign"></span>
                Radio is off
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="form-check-radio">
              <Form.Check.Label>
                <Form.Check.Input
                  defaultChecked
                  defaultValue="option2"
                  id="exampleRadios22"
                  name="exampleRadio"
                  type="radio"
                ></Form.Check.Input>
                <span className="form-check-sign"></span>
                Radio is on
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="form-check-radio" disabled>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultValue="option1"
                  disabled
                  id="exampleRadios31"
                  name="exampleRadio1"
                  type="radio"
                ></Form.Check.Input>
                <span className="form-check-sign"></span>
                Radio disabled is off
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="form-check-radio" disabled>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultChecked
                  defaultValue="option2"
                  disabled
                  id="exampleRadios32"
                  name="exampleRadio1"
                  type="radio"
                ></Form.Check.Input>
                <span className="form-check-sign"></span>
                Radio disabled is on
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <CodeMirror value={radioButtonsCode} options={options} />
        <h4>Props</h4>
        <p>
          Please refer to{" "}
          <a
            href="https://react-bootstrap.github.io/components/forms/#form-check-props"
            target="_blank"
          >
            react-bootstrap documentation
          </a>{" "}
          for more.
        </p>
      </div>
      <div className="tim-row">
        <h2>Switch</h2>
        <legend />
        <h4>Example code</h4>
        <Row>
          <Col md="4">
            <p className="category">Default</p>
            <Form.Check type="switch" id="custom-switch-11" className="mb-1" />
            <Form.Check type="switch" id="custom-switch-12" defaultChecked />
          </Col>
          <Col md="4">
            <p className="category">Disabled</p>
            <Form.Check
              disabled
              type="switch"
              id="custom-switch-21"
              className="mb-1"
            />
            <Form.Check
              disabled
              type="switch"
              id="custom-switch-22"
              defaultChecked
            />
            <span className="toggle"></span>
          </Col>
          <Col md="4">
            <p className="category">With Labels</p>
            <label className="d-flex align-items-center">
              <Form.Check type="switch" id="custom-switch-31" />
              <span className="ml-1">Switch me</span>
            </label>
            <label className="d-flex align-items-center">
              <Form.Check type="switch" id="custom-switch-32" defaultChecked />
              <span className="ml-1">Switch me</span>
            </label>
          </Col>
        </Row>
        <CodeMirror value={switchesCode} options={options} />
        <h4>Props</h4>
        <p>
          Please refer to{" "}
          <a
            href="https://react-bootstrap.github.io/components/forms/#form-check-props"
            target="_blank"
          >
            react-bootstrap documentation
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default CheckboxRadioSwitchSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};
const codeExample = `import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// react-bootstrap components
import {
  Row,
  Col,
} from "react-bootstrap";

function Example() {
  const [singleSelect, setSingleSelect] = React.useState("");
  const [multipleSelect, setMultipleSelect] = React.useState("");
  return (
    <>
      <Row>
        <Col md="6">
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="singleSelect"
            value={singleSelect}
            onChange={(value) => setSingleSelect(value)}
            options={[
              { value: "id", label: "Bahasa Indonesia" },
              { value: "ms", label: "Bahasa Melayu" },
              { value: "ca", label: "Català" },
              { value: "da", label: "Dansk" },
              { value: "de", label: "Deutsch" },
              { value: "en", label: "English" },
              { value: "es", label: "Español" },
              { value: "el", label: "Eλληνικά" },
              { value: "fr", label: "Français" },
              { value: "it", label: "Italiano" },
              { value: "hu", label: "Magyar" },
              { value: "nl", label: "Nederlands" },
              { value: "no", label: "Norsk" },
              { value: "pl", label: "Polski" },
              { value: "pt", label: "Português" },
              { value: "fi", label: "Suomi" },
              { value: "sv", label: "Svenska" },
              { value: "tr", label: "Türkçe" },
              { value: "is", label: "Íslenska" },
              { value: "cs", label: "Čeština" },
              { value: "ru", label: "Русский" },
              { value: "th", label: "ภาษาไทย" },
              { value: "zh", label: "中文 (简体)" },
              { value: "zh-TW", label: "中文 (繁體)" },
              { value: "ja", label: "日本語" },
              { value: "ko", label: "한국어" },
            ]}
            placeholder="Single Select"
          />
        </Col>
        <Col md="6">
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            placeholder="Choose City"
            name="multipleSelect"
            closeMenuOnSelect={false}
            isMulti
            value={multipleSelect}
            onChange={(value) => setMultipleSelect(value)}
            options={[
              { value: "id", label: "Bahasa Indonesia" },
              { value: "ms", label: "Bahasa Melayu" },
              { value: "ca", label: "Català" },
              { value: "da", label: "Dansk" },
              { value: "de", label: "Deutsch" },
              { value: "en", label: "English" },
              { value: "es", label: "Español" },
              { value: "el", label: "Eλληνικά" },
              { value: "fr", label: "Français" },
              { value: "it", label: "Italiano" },
              { value: "hu", label: "Magyar" },
              { value: "nl", label: "Nederlands" },
              { value: "no", label: "Norsk" },
              { value: "pl", label: "Polski" },
              { value: "pt", label: "Português" },
              { value: "fi", label: "Suomi" },
              { value: "sv", label: "Svenska" },
              { value: "tr", label: "Türkçe" },
              { value: "is", label: "Íslenska" },
              { value: "cs", label: "Čeština" },
              { value: "ru", label: "Русский" },
              { value: "th", label: "ภาษาไทย" },
              { value: "zh", label: "中文 (简体)" },
              { value: "zh-TW", label: "中文 (繁體)" },
              { value: "ja", label: "日本語" },
              { value: "ko", label: "한국어" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

export default Example;`;

class CustomizableSelectSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleSelect: null,
      multipleSelect: null,
    };
  }
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>React Select v3.1.1</h2>
          <legend />
          <h4>Example code</h4>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <Select
                    placeholder="Single Select"
                    name="singleSelect"
                    value={this.state.singleSelect}
                    options={[
                      { value: "id", label: "Bahasa Indonesia" },
                      { value: "ms", label: "Bahasa Melayu" },
                      { value: "ca", label: "Català" },
                      { value: "da", label: "Dansk" },
                      { value: "de", label: "Deutsch" },
                      { value: "en", label: "English" },
                      { value: "es", label: "Español" },
                      { value: "el", label: "Eλληνικά" },
                      { value: "fr", label: "Français" },
                      { value: "it", label: "Italiano" },
                      { value: "hu", label: "Magyar" },
                      { value: "nl", label: "Nederlands" },
                      { value: "no", label: "Norsk" },
                      { value: "pl", label: "Polski" },
                      { value: "pt", label: "Português" },
                      { value: "fi", label: "Suomi" },
                      { value: "sv", label: "Svenska" },
                      { value: "tr", label: "Türkçe" },
                      { value: "is", label: "Íslenska" },
                      { value: "cs", label: "Čeština" },
                      { value: "ru", label: "Русский" },
                      { value: "th", label: "ภาษาไทย" },
                      { value: "zh", label: "中文 (简体)" },
                      { value: "zh-TW", label: "中文 (繁體)" },
                      { value: "ja", label: "日本語" },
                      { value: "ko", label: "한국어" },
                    ]}
                    onChange={(value) => this.setState({ singleSelect: value })}
                  />
                </Col>
                <Col md={6}>
                  <Select
                    placeholder="Multiple Select"
                    closeOnSelect={false}
                    isMulti={true}
                    name="multipleSelect"
                    value={this.state.multipleSelect}
                    options={[
                      { value: "id", label: "Bahasa Indonesia" },
                      { value: "ms", label: "Bahasa Melayu" },
                      { value: "ca", label: "Català" },
                      { value: "da", label: "Dansk" },
                      { value: "de", label: "Deutsch" },
                      { value: "en", label: "English" },
                      { value: "es", label: "Español" },
                      { value: "el", label: "Eλληνικά" },
                      { value: "fr", label: "Français" },
                      { value: "it", label: "Italiano" },
                      { value: "hu", label: "Magyar" },
                      { value: "nl", label: "Nederlands" },
                      { value: "no", label: "Norsk" },
                      { value: "pl", label: "Polski" },
                      { value: "pt", label: "Português" },
                      { value: "fi", label: "Suomi" },
                      { value: "sv", label: "Svenska" },
                      { value: "tr", label: "Türkçe" },
                      { value: "is", label: "Íslenska" },
                      { value: "cs", label: "Čeština" },
                      { value: "ru", label: "Русский" },
                      { value: "th", label: "ภาษาไทย" },
                      { value: "zh", label: "中文 (简体)" },
                      { value: "zh-TW", label: "中文 (繁體)" },
                      { value: "ja", label: "日本語" },
                      { value: "ko", label: "한국어" },
                    ]}
                    onChange={(value) => {
                      this.setState({ multipleSelect: value });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <CodeMirror value={codeExample} options={options} />
          <br />
          <h4>Props</h4>
          <p>
            Please refer to{" "}
            <a
              href="https://github.com/JedWatson/react-select#usage"
              target="_blank"
            >
              react-select documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default CustomizableSelectSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};
const importCode = `import { ProgressBar } from 'react-bootstrap';`;
const exampleCode = `<ProgressBar
    now={30}
    className="progress-line-primary"
    label="30% Complete" srOnly
/>
<ProgressBar
    variant="info"
    now={60}
    className="progress-line-info"
    label="60% Complete" srOnly
/>
<ProgressBar>
    <ProgressBar
        variant="success"
        now={35}
        key={1}
        label="35% Complete (success)"
        srOnly
    />
    <ProgressBar
        variant="warning"
        now={20}
        key={2}
        label="20% Complete (warning)"
        srOnly
    />
    <ProgressBar
        active
        variant="danger"
        now={10}
        key={3}
        label="10% Complete (danger)"
        srOnly
    />
</ProgressBar>`;
class ProgressBarsSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Progress Bars</h2>
          <legend />
          <p>
            The progress bars from React-Bootstrap hold the same classes and
            functionality. Adding this dashboard over your existing project will
            only make it look more clean and minimal.
          </p>
          <p>To use them, simply import them like so:</p>
          <CodeMirror value={importCode} options={options} />
          <br />
          <h4>Example Code</h4>
          <ProgressBar
            now={30}
            className="progress-line-primary"
            label="30% Complete"
            srOnly
          />
          <ProgressBar
            variant="info"
            now={60}
            className="progress-line-info"
            label="60% Complete"
            srOnly
          />
          <ProgressBar>
            <ProgressBar
              variant="success"
              now={35}
              key={1}
              label="35% Complete (success)"
              srOnly
            />
            <ProgressBar
              variant="warning"
              now={20}
              key={2}
              label="20% Complete (warning)"
              srOnly
            />
            <ProgressBar
              active
              variant="danger"
              now={10}
              key={3}
              label="10% Complete (danger)"
              srOnly
            />
          </ProgressBar>
          <CodeMirror value={exampleCode} options={options} />
          <h4>Props</h4>
          <p>
            Please refer to{" "}
            <a
              href="https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/progress/#progress-props"
              target="_blank"
            >
              react-bootstrap documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default ProgressBarsSection;

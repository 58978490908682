/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};
const codeImport = `import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';`;
const codeExampleTooltips = `const edit = (<Tooltip id="edit_tooltip">Edit Task</Tooltip>);
const remove = (<Tooltip id="remove_tooltip">Remove</Tooltip>);`;
const codeExample = `<OverlayTrigger placement="top" overlay={edit}>
    <Button
        variant="info"
        type="button"
        size="xs"
    >
        <i className="fa fa-edit"></i>
    </Button>
</OverlayTrigger>

<OverlayTrigger placement="top" overlay={remove}>
    <Button
        variant="danger"
        type="button"
        size="xs"
    >
        <i className="fa fa-times"></i>
    </Button>
</OverlayTrigger>`;
class TooltipSection extends Component {
  render() {
    const edit = <Tooltip id="edit_tooltip">Edit Task</Tooltip>;
    const remove = <Tooltip id="remove_tooltip">Remove</Tooltip>;
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Tooltips</h2>
          <legend />
          <p>
            You will see the usage of these components in the <code>Table</code>{" "}
            section.
          </p>
          <h4>Imports</h4>
          <p>For this component you'll need the following imports:</p>
          <CodeMirror value={codeImport} options={options} />
          <h4>Example</h4>
          <OverlayTrigger placement="top" overlay={edit}>
            <Button variant="info" simple type="button" size="xs">
              <i className="fa fa-edit" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={remove}>
            <Button variant="danger" simple type="button" size="xs">
              <i className="fa fa-times" />
            </Button>
          </OverlayTrigger>
          <CodeMirror value={codeExample} options={options} />
          <p>
            And the definition of the <code>Tooltip</code>s:
          </p>
          <CodeMirror value={codeExampleTooltips} options={options} />
        </div>
      </div>
    );
  }
}

export default TooltipSection;

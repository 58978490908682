/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Link } from "react-router-dom";

class FooterSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Footers</h2>
          <legend />
          <p>
            The footer components are located in{" "}
            <code>{"scr/components/Footers/*.js"}</code>. Feel free to change
            the links in there.
          </p>
          <p>
            Please check the admin footer{" "}
            <Link to="/admin/dashboard">here</Link>, and the auth one{" "}
            <Link to="/auth/login-page">here</Link>.
          </p>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default FooterSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, InputGroup, Navbar, Nav, Container } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};

const coderb = `import React, { Component } from "react";

import { Form, InputGroup, Navbar, Nav, Container } from "react-bootstrap";

class Example extends Component {
  render() {
    return (
      <>
        <Navbar className="navbar-ct-blue" expand="lg">
          <Container fluid>
            <div className="navbar-header">
              <Navbar.Toggle
                aria-controls="navigation-index"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler navbar-toggler-right"
                data-toggle="collapse"
                type="button"
              >
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
              </Navbar.Toggle>
              <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                Dashboard
              </Navbar.Brand>
            </div>
            <Navbar.Collapse className="collapse">
              <Nav className="nav" navbar></Nav>
              <Form
                className="navbar-form navbar-left navbar-search-form"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
              <Nav className="nav ml-auto" navbar>
                <Nav.Item>
                  <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="nc-icon nc-planet"></i>
                    <span className="stats">Stats</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <br />
        <Navbar className="navbar-ct-azure" expand="lg">
          <Container fluid>
            <div className="navbar-header">
              <Navbar.Toggle
                aria-controls="navigation-index"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler navbar-toggler-right"
                data-toggle="collapse"
                type="button"
              >
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
              </Navbar.Toggle>
              <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                Dashboard
              </Navbar.Brand>
            </div>
            <Navbar.Collapse className="collapse">
              <Nav className="nav" navbar></Nav>
              <Form
                className="navbar-form navbar-left navbar-search-form"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
              <Nav className="nav ml-auto" navbar>
                <Nav.Item>
                  <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="nc-icon nc-planet"></i>
                    <span className="stats">Stats</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <br />
        <Navbar className="navbar-ct-green" expand="lg">
          <Container fluid>
            <div className="navbar-header">
              <Navbar.Toggle
                aria-controls="navigation-index"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler navbar-toggler-right"
                data-toggle="collapse"
                type="button"
              >
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
              </Navbar.Toggle>
              <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                Dashboard
              </Navbar.Brand>
            </div>
            <Navbar.Collapse className="collapse">
              <Nav className="nav" navbar></Nav>
              <Form
                className="navbar-form navbar-left navbar-search-form"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
              <Nav className="nav ml-auto" navbar>
                <Nav.Item>
                  <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="nc-icon nc-planet"></i>
                    <span className="stats">Stats</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <br />
        <Navbar className="navbar-ct-orange" expand="lg">
          <Container fluid>
            <div className="navbar-header">
              <Navbar.Toggle
                aria-controls="navigation-index"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler navbar-toggler-right"
                data-toggle="collapse"
                type="button"
              >
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
              </Navbar.Toggle>
              <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                Dashboard
              </Navbar.Brand>
            </div>
            <Navbar.Collapse className="collapse">
              <Nav className="nav" navbar></Nav>
              <Form
                className="navbar-form navbar-left navbar-search-form"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
              <Nav className="nav ml-auto" navbar>
                <Nav.Item>
                  <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="nc-icon nc-planet"></i>
                    <span className="stats">Stats</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <br />
        <Navbar className="navbar-ct-red" expand="lg">
          <Container fluid>
            <div className="navbar-header">
              <Navbar.Toggle
                aria-controls="navigation-index"
                aria-expanded={false}
                aria-label="Toggle navigation"
                className="navbar-toggler navbar-toggler-right"
                data-toggle="collapse"
                type="button"
              >
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
                <span className="navbar-toggler-bar burger-lines"></span>
              </Navbar.Toggle>
              <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                Dashboard
              </Navbar.Brand>
            </div>
            <Navbar.Collapse className="collapse">
              <Nav className="nav" navbar></Nav>
              <Form
                className="navbar-form navbar-left navbar-search-form"
                role="search"
              >
                <InputGroup>
                  <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                  <Form.Control
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                  ></Form.Control>
                </InputGroup>
              </Form>
              <Nav className="nav ml-auto" navbar>
                <Nav.Item>
                  <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="nc-icon nc-planet"></i>
                    <span className="stats">Stats</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <br />
      </>
    );
  }
}

export default Example;
`;

class NavigationSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Navbar</h2>
          <legend />
          <p>
            We restyled the classic Bootstrap Navbar and created a component for
            our demo navbar.
          </p>
          <h4>Core Navbars</h4>
          <p>
            You will find all the core navbars inside{" "}
            <code>{`src/components/Navbars/*.js`}</code>.
          </p>
          <p>
            Please check the admin navbar{" "}
            <Link to="/admin/dashboard">here</Link>, and the auth one{" "}
            <Link to="/auth/login-page">here</Link>.
          </p>
          <h4>React Bootstrap Navbars</h4>
          <Navbar className="navbar-ct-blue" expand="lg">
            <Container fluid>
              <div className="navbar-header">
                <Navbar.Toggle
                  aria-controls="navigation-index"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler navbar-toggler-right"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                  Dashboard
                </Navbar.Brand>
              </div>
              <Navbar.Collapse className="collapse">
                <Nav className="nav" navbar></Nav>
                <Form
                  className="navbar-form navbar-left navbar-search-form"
                  role="search"
                >
                  <InputGroup>
                    <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                    <Form.Control
                      defaultValue=""
                      placeholder="Search..."
                      type="text"
                    ></Form.Control>
                  </InputGroup>
                </Form>
                <Nav className="nav ml-auto" navbar>
                  <Nav.Item>
                    <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="nc-icon nc-planet"></i>
                      <span className="stats">Stats</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <br />
          <Navbar className="navbar-ct-azure" expand="lg">
            <Container fluid>
              <div className="navbar-header">
                <Navbar.Toggle
                  aria-controls="navigation-index"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler navbar-toggler-right"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                  Dashboard
                </Navbar.Brand>
              </div>
              <Navbar.Collapse className="collapse">
                <Nav className="nav" navbar></Nav>
                <Form
                  className="navbar-form navbar-left navbar-search-form"
                  role="search"
                >
                  <InputGroup>
                    <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                    <Form.Control
                      defaultValue=""
                      placeholder="Search..."
                      type="text"
                    ></Form.Control>
                  </InputGroup>
                </Form>
                <Nav className="nav ml-auto" navbar>
                  <Nav.Item>
                    <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="nc-icon nc-planet"></i>
                      <span className="stats">Stats</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <br />
          <Navbar className="navbar-ct-green" expand="lg">
            <Container fluid>
              <div className="navbar-header">
                <Navbar.Toggle
                  aria-controls="navigation-index"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler navbar-toggler-right"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                  Dashboard
                </Navbar.Brand>
              </div>
              <Navbar.Collapse className="collapse">
                <Nav className="nav" navbar></Nav>
                <Form
                  className="navbar-form navbar-left navbar-search-form"
                  role="search"
                >
                  <InputGroup>
                    <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                    <Form.Control
                      defaultValue=""
                      placeholder="Search..."
                      type="text"
                    ></Form.Control>
                  </InputGroup>
                </Form>
                <Nav className="nav ml-auto" navbar>
                  <Nav.Item>
                    <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="nc-icon nc-planet"></i>
                      <span className="stats">Stats</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <br />
          <Navbar className="navbar-ct-orange" expand="lg">
            <Container fluid>
              <div className="navbar-header">
                <Navbar.Toggle
                  aria-controls="navigation-index"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler navbar-toggler-right"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                  Dashboard
                </Navbar.Brand>
              </div>
              <Navbar.Collapse className="collapse">
                <Nav className="nav" navbar></Nav>
                <Form
                  className="navbar-form navbar-left navbar-search-form"
                  role="search"
                >
                  <InputGroup>
                    <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                    <Form.Control
                      defaultValue=""
                      placeholder="Search..."
                      type="text"
                    ></Form.Control>
                  </InputGroup>
                </Form>
                <Nav className="nav ml-auto" navbar>
                  <Nav.Item>
                    <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="nc-icon nc-planet"></i>
                      <span className="stats">Stats</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <br />
          <Navbar className="navbar-ct-red" expand="lg">
            <Container fluid>
              <div className="navbar-header">
                <Navbar.Toggle
                  aria-controls="navigation-index"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler navbar-toggler-right"
                  data-toggle="collapse"
                  type="button"
                >
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                  <span className="navbar-toggler-bar burger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
                  Dashboard
                </Navbar.Brand>
              </div>
              <Navbar.Collapse className="collapse">
                <Nav className="nav" navbar></Nav>
                <Form
                  className="navbar-form navbar-left navbar-search-form"
                  role="search"
                >
                  <InputGroup>
                    <i className="nc-icon nc-zoom-split text-white font-weight-bold"></i>
                    <Form.Control
                      defaultValue=""
                      placeholder="Search..."
                      type="text"
                    ></Form.Control>
                  </InputGroup>
                </Form>
                <Nav className="nav ml-auto" navbar>
                  <Nav.Item>
                    <Nav.Link href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="nc-icon nc-planet"></i>
                      <span className="stats">Stats</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <br />
          <CodeMirror value={coderb} options={options} />
          <h4>Props</h4>
          <p>
            Please refer{" "}
            <a
              href="https://react-bootstrap.netlify.app/components/navbar/"
              target="_blank"
            >
              react-bootstrap documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default NavigationSection;

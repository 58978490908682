/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";
import { Table } from "react-bootstrap";

import { Button } from "react-bootstrap";

const options = {
  mode: "jsx",
  readOnly: true,
};

const importCode = `import { Button } from "react-bootstrap";`;

const colorsCode = `<Button variant="default">Default</Button>
<Button variant="primary">Primary</Button>
<Button variant="info">Info</Button>
<Button variant="success">Success</Button>
<Button variant="warning">Warning</Button>
<Button variant="danger">Danger</Button>`;

const sizesCode = `<Button size="lg">Large</Button>
<Button>Normal</Button>
<Button size="sm">Small</Button>`;

const stylesCode = `<Button variant="primary">Default</Button>
<Button variant="outline-primary" className="btn-outline">Outline</Button>
<Button variant="primary" className="btn-round">Round</Button>
<Button variant="primary" className="btn-link">Link</Button>`;

const socialsCode = `<Button className="btn-fill btn-social" variant="twitter"><i className="fab fa-twitter"></i></Button>
<Button className="btn-fill btn-social" variant="facebook"><i className="fab fa-facebook"> </i></Button>
<Button className="btn-fill btn-social" variant="google"><i className="fab fa-google-plus"> </i></Button>
<Button className="btn-fill btn-social" variant="linkedin"><i className="fab fa-linkedin"></i></Button>
<Button className="btn-fill btn-social" variant="pinterest"><i className="fab fa-pinterest"></i></Button>
<Button className="btn-fill btn-social" variant="youtube"><i className="fab fa-youtube"></i></Button>
<Button className="btn-fill btn-social" variant="tumblr"><i className="fab fa-tumblr"> </i></Button>
<Button className="btn-fill btn-social" variant="github"><i className="fab fa-github"></i></Button>
<Button className="btn-fill btn-social" variant="behance"><i className="fab fa-behance"></i></Button>
<Button className="btn-fill btn-social" variant="dribbble"><i className="fab fa-dribbble"></i></Button>
<Button className="btn-fill btn-social" variant="reddit"><i className="fab fa-reddit"></i></Button>
<Button className="btn-fill btn-social" variant="stumbleupon"><i className="fab fa-stumbleupon"></i></Button>`;

class ButtonsSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <h2>Buttons</h2>
        <legend />
        <h4>Import</h4>
        <p>
          If you want to use our custom made buttons you need to import them
          like so:
        </p>
        <CodeMirror value={importCode} options={options} />
        <h4>Color</h4>
        <p>
          We worked over the original Bootstrap classes, choosing a different,
          slightly intenser color pallete:
        </p>
        <div
          style={{
            backgroundColor: "#D5D5D5",
            display: "inline-block",
            padding: "5px 5px",
            borderRadius: "5px",
          }}
        >
          <Button variant="default">Default</Button>
          <Button variant="primary">Primary</Button>
          <Button variant="info">Info</Button>
          <Button variant="success">Success</Button>
          <Button variant="warning">Warning</Button>
          <Button variant="danger">Danger</Button>
        </div>
        <br />
        <br />
        <CodeMirror value={colorsCode} options={options} />
        <h4>Sizes</h4>
        <p>Buttons come in all needed sizes:</p>
        <p>
          <Button size="lg">Large</Button>
          <Button>Normal</Button>
          <Button size="sm">Small</Button>
        </p>
        <CodeMirror value={sizesCode} options={options} />
        <h4>Styles</h4>
        <p>
          We added extra props that can help you better customise the look. You
          can use regular buttons, filled buttons, rounded corners buttons,
          minimum width, icons or plain link buttons. Let{"'"}s see some
          examples:
        </p>
        <p>
          <Button variant="primary">Default</Button>
          <Button variant="outline-primary" className="btn-outline">
            Outline
          </Button>
          <Button variant="primary" className="btn-round">
            Round
          </Button>
          <Button variant="primary" className="btn-link">
            Link
          </Button>
        </p>
        <CodeMirror value={stylesCode} options={options} />
        <h4>Social Buttons</h4>
        <p>
          An extra category of buttons that are necessary for any project is the
          social buttons. We have added props that provide the default color for
          every social network.{" "}
        </p>
        <p>
          To use them, you have to add to your button the specific network as a
          prop, for example <code>twitter</code>. All styles described above can
          be applied to social buttons, ranging from filled to round to simple.{" "}
        </p>
        <p>
          We used the Font Awesome social icons that you can find{" "}
          <a
            href="https://fortawesome.github.io/Font-Awesome/icons/"
            target="_blank"
          >
            here
          </a>
          .{" "}
        </p>
        <p>
          Here is the example for all the social buttons with the filled style:
        </p>
        <p>
          <Button className="btn-fill btn-social" variant="twitter">
            <i className="fab fa-twitter" />
          </Button>
          <Button className="btn-fill btn-social" variant="facebook">
            <i className="fab fa-facebook"> </i>
          </Button>
          <Button className="btn-fill btn-social" variant="google">
            <i className="fab fa-google-plus"> </i>
          </Button>
          <Button className="btn-fill btn-social" variant="linkedin">
            <i className="fab fa-linkedin" />
          </Button>
          <Button className="btn-fill btn-social" variant="pinterest">
            <i className="fab fa-pinterest" />
          </Button>
          <Button className="btn-fill btn-social" variant="youtube">
            <i className="fab fa-youtube" />
          </Button>
          <Button className="btn-fill btn-social" variant="tumblr">
            <i className="fab fa-tumblr"> </i>
          </Button>
          <Button className="btn-fill btn-social" variant="github">
            <i className="fab fa-github" />
          </Button>
          <Button className="btn-fill btn-social" variant="behance">
            <i className="fab fa-behance" />
          </Button>
          <Button className="btn-fill btn-social" variant="dribbble">
            <i className="fab fa-dribbble" />
          </Button>
          <Button className="btn-fill btn-social" variant="reddit">
            <i className="fab fa-reddit" />
          </Button>
          <Button className="btn-fill btn-social" variant="stumbleupon">
            <i className="fab fa-stumbleupon" />
          </Button>
        </p>
        <CodeMirror value={socialsCode} options={options} />
        <h4>Props</h4>
        <p>
          You can refer to{" "}
          <a
            href="https://react-bootstrap.github.io/components/buttons/#api"
            target="_blank"
          >
            react-bootstrap documentation
          </a>{" "}
          for more props.
        </p>
      </div>
    );
  }
}

export default ButtonsSection;

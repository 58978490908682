/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

// react-bootstrap components
import {
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Tab,
  Card,
} from "react-bootstrap";

const options = {
  mode: "jsx",
  readOnly: true,
};

const codeExampleSimple = `import React from "react";

// react-bootstrap components
import {
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Tab,
} from "react-bootstrap";

function Exmaple() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="6">
            <Tab.Container
              id="plain-tabs-example"
              defaultActiveKey="info-plain"
            >
              <Nav role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="info-plain">Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="account-plain">Account</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="style-plain">Style</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings-plain">Settings</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="info-plain">
                  Agency is a group of professional individuals looking to
                  create amazing pieces of clothing. We have studied at the
                  best schools of design, we have tailored the suits for the
                  most stylish men in the industry, we are what you need!
                </Tab.Pane>
                <Tab.Pane eventKey="account-plain">
                  We are Houses Inc., a group of architects and interior
                  designers based in Chicago and operating for clients
                  worldwide. We’ve been designing stunningly beautiful
                  houses and making clients happy for years.
                </Tab.Pane>
                <Tab.Pane eventKey="style-plain">
                  Explore a wide variety of styles, personalise your
                  finishes, and let us design the perfect home for you. It
                  {"'"}s what we do best and you can see proof in the
                  products and reviews below.
                </Tab.Pane>
                <Tab.Pane eventKey="settings-plain">
                  Explore a wide Houses Inc., a group of architects and
                  interior designers based in Chicago and operating for
                  clients worldwide. We’ve been designing stunningly
                  beautiful houses and making clients happy for years.
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col md="6">
            <Tab.Container
              id="icons-tabs-example"
              defaultActiveKey="info-icons"
            >
              <Nav role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="info-icons">
                    <i className="fas fa-info-circle"></i> Info
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="account-icons">
                    <i className="fas fa-user"></i> Account
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="style-icons">
                    <i className="fas fa-cube"></i> Style
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings-icons">
                    <i className="fas fa-cog"></i> Settings
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="info-icons">
                  Agency is a group of professional individuals looking to
                  create amazing pieces of clothing. We have studied at the
                  best schools of design, we have tailored the suits for the
                  most stylish men in the industry, we are what you need!
                </Tab.Pane>
                <Tab.Pane eventKey="account-icons">
                  We are Houses Inc., a group of architects and interior
                  designers based in Chicago and operating for clients
                  worldwide. We’ve been designing stunningly beautiful
                  houses and making clients happy for years.
                </Tab.Pane>
                <Tab.Pane eventKey="style-icons">
                  Explore a wide variety of styles, personalise your
                  finishes, and let us design the perfect home for you. It
                  {"'"}s what we do best and you can see proof in the
                  products and reviews below.
                </Tab.Pane>
                <Tab.Pane eventKey="settings-icons">
                  Explore a wide Houses Inc., a group of architects and
                  interior designers based in Chicago and operating for
                  clients worldwide. We’ve been designing stunningly
                  beautiful houses and making clients happy for years.
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Exmaple;
`;
const codePageSubExample = `import React from "react";

// react-bootstrap components
import {
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Tab,
  Card
} from "react-bootstrap";

function Panels() {
  return (
    <>
      <Tab.Container
        id="page-subcategories-tabs-example"
        defaultActiveKey="description-page-subcategories"
      >
        <div className="nav-container">
          <Nav
            role="tablist"
            variant="tabs"
            className="justify-content-center border-0 nav-icons"
          >
            <Nav.Item>
              <Nav.Link
                eventKey="description-page-subcategories"
                className="border-0 bg-transparent"
              >
                <i className="nc-icon nc-notes"></i>
                <br></br>
                Description
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="location-page-subcategories"
                className="border-0 bg-transparent"
              >
                <i className="nc-icon nc-pin-3"></i>
                <br></br>
                Location
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="legal-info-page-subcategories"
                className="border-0 bg-transparent"
              >
                <i className="nc-icon nc-bank"></i>
                <br></br>
                Legal Info
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="help-center-page-subcategories"
                className="border-0 bg-transparent"
              >
                <i className="nc-icon nc-support-17"></i>
                <br></br>
                Help Center
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="description-page-subcategories">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Description about product</Card.Title>
                <p className="card-category">More information here</p>
              </Card.Header>
              <Card.Body>
                <p>
                  Larger, yet dramatically thinner. More powerful, but
                  remarkably power efficient. With a smooth metal surface
                  that seamlessly meets the new Retina HD display.
                </p>
                <p>
                  The first thing you notice when you hold the phone is
                  how great it feels in your hand. There are no distinct
                  edges. No gaps. Just a smooth, seamless bond of metal
                  and glass that feels like one continuous surface.
                </p>
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey="location-page-subcategories">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Location of product</Card.Title>
                <p className="category">Here is some text</p>
              </Card.Header>
              <Card.Body>
                <p>
                  Another Text. The first thing you notice when you hold
                  the phone is how great it feels in your hand. The cover
                  glass curves down around the sides to meet the anodized
                  aluminum enclosure in a remarkable, simplified design.
                </p>
                <p>
                  Larger, yet dramatically thinner.It’s one continuous
                  form where hardware and software function in perfect
                  unison, creating a new generation of phone that’s better
                  by any measure.
                </p>
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey="legal-info-page-subcategories">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Legal items</Card.Title>
                <p className="category">More information here</p>
              </Card.Header>
              <Card.Body>
                <p>
                  The first thing you notice when you hold the phone is
                  how great it feels in your hand. The cover glass curves
                  down around the sides to meet the anodized aluminum
                  enclosure in a remarkable, simplified design.
                </p>
                <p>
                  Larger, yet dramatically thinner.It’s one continuous
                  form where hardware and software function in perfect
                  unison, creating a new generation of phone that’s better
                  by any measure.
                </p>
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey="help-center-page-subcategories">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Help center</Card.Title>
                <p className="category">More information here</p>
              </Card.Header>
              <Card.Body>
                <p>
                  From the seamless transition of glass and metal to the
                  streamlined profile, every detail was carefully
                  considered to enhance your experience. So while its
                  display is larger, the phone feels just right.
                </p>
                <p>
                  Another Text. The first thing you notice when you hold
                  the phone is how great it feels in your hand. The cover
                  glass curves down around the sides to meet the anodized
                  aluminum enclosure in a remarkable, simplified design.
                </p>
              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

export default Panels;
`;
function TabsSection() {
  return (
    <div className="tim-container">
      <div className="tim-row">
        <h2>Tabs</h2>
        <legend />
        <p>
          If you have information that you don't need to show at once, we
          suggest you use tabs. The first option for tabs is the plain text tabs
          and the second also contains icons.
        </p>
        <h4>Example Code</h4>
        <Row>
          <Col md="6">
            <Tab.Container
              id="plain-tabs-example"
              defaultActiveKey="info-plain"
            >
              <Nav role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="info-plain">Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="account-plain">Account</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="style-plain">Style</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings-plain">Settings</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="info-plain">
                  Agency is a group of professional individuals looking to
                  create amazing pieces of clothing. We have studied at the best
                  schools of design, we have tailored the suits for the most
                  stylish men in the industry, we are what you need!
                </Tab.Pane>
                <Tab.Pane eventKey="account-plain">
                  We are Houses Inc., a group of architects and interior
                  designers based in Chicago and operating for clients
                  worldwide. We’ve been designing stunningly beautiful houses
                  and making clients happy for years.
                </Tab.Pane>
                <Tab.Pane eventKey="style-plain">
                  Explore a wide variety of styles, personalise your finishes,
                  and let us design the perfect home for you. It
                  {"'"}s what we do best and you can see proof in the products
                  and reviews below.
                </Tab.Pane>
                <Tab.Pane eventKey="settings-plain">
                  Explore a wide Houses Inc., a group of architects and interior
                  designers based in Chicago and operating for clients
                  worldwide. We’ve been designing stunningly beautiful houses
                  and making clients happy for years.
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col md="6">
            <Tab.Container
              id="icons-tabs-example"
              defaultActiveKey="info-icons"
            >
              <Nav role="tablist" variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="info-icons">
                    <i className="fas fa-info-circle"></i> Info
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="account-icons">
                    <i className="fas fa-user"></i> Account
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="style-icons">
                    <i className="fas fa-cube"></i> Style
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings-icons">
                    <i className="fas fa-cog"></i> Settings
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="info-icons">
                  Agency is a group of professional individuals looking to
                  create amazing pieces of clothing. We have studied at the best
                  schools of design, we have tailored the suits for the most
                  stylish men in the industry, we are what you need!
                </Tab.Pane>
                <Tab.Pane eventKey="account-icons">
                  We are Houses Inc., a group of architects and interior
                  designers based in Chicago and operating for clients
                  worldwide. We’ve been designing stunningly beautiful houses
                  and making clients happy for years.
                </Tab.Pane>
                <Tab.Pane eventKey="style-icons">
                  Explore a wide variety of styles, personalise your finishes,
                  and let us design the perfect home for you. It
                  {"'"}s what we do best and you can see proof in the products
                  and reviews below.
                </Tab.Pane>
                <Tab.Pane eventKey="settings-icons">
                  Explore a wide Houses Inc., a group of architects and interior
                  designers based in Chicago and operating for clients
                  worldwide. We’ve been designing stunningly beautiful houses
                  and making clients happy for years.
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
        <CodeMirror value={codeExampleSimple} options={options} />
        <h2>Page Subcategories</h2>
        <legend />
        <p>
          For something that looks more modular, you can use the page
          subcategories. It{"'"}s basically <code>Tab</code>s but with some more
          of our design.
        </p>
        <h4>Example Code</h4>
        <Tab.Container
          id="page-subcategories-tabs-example"
          defaultActiveKey="description-page-subcategories"
        >
          <div className="nav-container">
            <Nav
              role="tablist"
              variant="tabs"
              className="justify-content-center border-0 nav-icons"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="description-page-subcategories"
                  className="border-0 bg-transparent"
                >
                  <i className="nc-icon nc-notes"></i>
                  <br></br>
                  Description
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="location-page-subcategories"
                  className="border-0 bg-transparent"
                >
                  <i className="nc-icon nc-pin-3"></i>
                  <br></br>
                  Location
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="legal-info-page-subcategories"
                  className="border-0 bg-transparent"
                >
                  <i className="nc-icon nc-bank"></i>
                  <br></br>
                  Legal Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="help-center-page-subcategories"
                  className="border-0 bg-transparent"
                >
                  <i className="nc-icon nc-support-17"></i>
                  <br></br>
                  Help Center
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="description-page-subcategories">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Description about product</Card.Title>
                  <p className="card-category">More information here</p>
                </Card.Header>
                <Card.Body>
                  <p>
                    Larger, yet dramatically thinner. More powerful, but
                    remarkably power efficient. With a smooth metal surface that
                    seamlessly meets the new Retina HD display.
                  </p>
                  <p>
                    The first thing you notice when you hold the phone is how
                    great it feels in your hand. There are no distinct edges. No
                    gaps. Just a smooth, seamless bond of metal and glass that
                    feels like one continuous surface.
                  </p>
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="location-page-subcategories">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Location of product</Card.Title>
                  <p className="category">Here is some text</p>
                </Card.Header>
                <Card.Body>
                  <p>
                    Another Text. The first thing you notice when you hold the
                    phone is how great it feels in your hand. The cover glass
                    curves down around the sides to meet the anodized aluminum
                    enclosure in a remarkable, simplified design.
                  </p>
                  <p>
                    Larger, yet dramatically thinner.It’s one continuous form
                    where hardware and software function in perfect unison,
                    creating a new generation of phone that’s better by any
                    measure.
                  </p>
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="legal-info-page-subcategories">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Legal items</Card.Title>
                  <p className="category">More information here</p>
                </Card.Header>
                <Card.Body>
                  <p>
                    The first thing you notice when you hold the phone is how
                    great it feels in your hand. The cover glass curves down
                    around the sides to meet the anodized aluminum enclosure in
                    a remarkable, simplified design.
                  </p>
                  <p>
                    Larger, yet dramatically thinner.It’s one continuous form
                    where hardware and software function in perfect unison,
                    creating a new generation of phone that’s better by any
                    measure.
                  </p>
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="help-center-page-subcategories">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Help center</Card.Title>
                  <p className="category">More information here</p>
                </Card.Header>
                <Card.Body>
                  <p>
                    From the seamless transition of glass and metal to the
                    streamlined profile, every detail was carefully considered
                    to enhance your experience. So while its display is larger,
                    the phone feels just right.
                  </p>
                  <p>
                    Another Text. The first thing you notice when you hold the
                    phone is how great it feels in your hand. The cover glass
                    curves down around the sides to meet the anodized aluminum
                    enclosure in a remarkable, simplified design.
                  </p>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <CodeMirror value={codePageSubExample} options={options} />
        <h4>Props</h4>
        <p>
          We've used react-bootstrap tags, if you want to see all the props,
          please refer to{" "}
          <a
            href="https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/tabs/#tabs-props"
            target="_blank"
          >
            react-bootstrap documentation
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default TabsSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { FormGroup, Form, FormControl } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const code = `<FormGroup controlId="formControlsTextarea">
    <Form.Label>Textarea</Form.Label>
    <FormControl as="textarea" row="3" />
</FormGroup>`;
const codeImport = `import { FormGroup, Form, FormControl } from 'react-bootstrap';`;
const options = {
  mode: "jsx",
  readOnly: true,
};
class TextareaSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Textarea</h2>
          <legend />
          <p>
            We added custom style for the textarea, so it looks similar to all
            other inputs.
          </p>
          <FormGroup controlId="formControlsTextarea">
            <Form.Label>Textarea</Form.Label>
            <FormControl as="textarea" row="3" />
          </FormGroup>
          <br />
          <CodeMirror value={code} options={options} />
          <br />
          <p>
            As you can see we've used the default react-bootstrap tags for this,
            the only changes were made in css, so you just have to import these
            tags from react-bootstrap like so:
          </p>
          <CodeMirror value={codeImport} options={options} />
        </div>
      </div>
    );
  }
}

export default TextareaSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Pagination } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

const options = {
  mode: "jsx",
  readOnly: true,
};
const codeImport = `import { Pagination } from 'react-bootstrap';`;
const codePagnation = `<Pagination>
  <Pagination.First />
  <Pagination.Item>{1}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item active>{3}</Pagination.Item>
  <Pagination.Item>{4}</Pagination.Item>
  <Pagination.Item>{5}</Pagination.Item>
  <Pagination.Last />
</Pagination>
<Pagination className="pagination-no-border">
  <Pagination.First />
  <Pagination.Item>{1}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item active>{3}</Pagination.Item>
  <Pagination.Item>{4}</Pagination.Item>
  <Pagination.Item>{5}</Pagination.Item>
  <Pagination.Last />
</Pagination>`;
const codePagnationColors = `<Pagination className="pagination-blue">
  <Pagination.First />
  <Pagination.Item>{1}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item active>{3}</Pagination.Item>
  <Pagination.Item>{4}</Pagination.Item>
  <Pagination.Item>{5}</Pagination.Item>
  <Pagination.Last />
</Pagination>
<Pagination className="pagination-green">
  <Pagination.First />
  <Pagination.Item>{1}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item active>{3}</Pagination.Item>
  <Pagination.Item>{4}</Pagination.Item>
  <Pagination.Item>{5}</Pagination.Item>
  <Pagination.Last />
</Pagination>
<Pagination className="pagination-orange">
  <Pagination.First />
  <Pagination.Item>{1}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item active>{3}</Pagination.Item>
  <Pagination.Item>{4}</Pagination.Item>
  <Pagination.Item>{5}</Pagination.Item>
  <Pagination.Last />
</Pagination>
<Pagination className="pagination-red">
  <Pagination.First />
  <Pagination.Item>{1}</Pagination.Item>
  <Pagination.Item>{2}</Pagination.Item>
  <Pagination.Item active>{3}</Pagination.Item>
  <Pagination.Item>{4}</Pagination.Item>
  <Pagination.Item>{5}</Pagination.Item>
  <Pagination.Last />
</Pagination>`;
class PaginationSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Pagination</h2>
          <legend />
          <p>
            We took the React-Bootstrap pagination elements and customised them
            to fit the overall theme. We also added a special{" "}
            <code>className</code> called <code>.pagination-no-border</code>,
            which does what its name says and improves the overall clean look.
          </p>
          <p>To use them, you'll have to import them:</p>
          <CodeMirror value={codeImport} options={options} />
          <Pagination>
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item active>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Item>{5}</Pagination.Item>
            <Pagination.Last />
          </Pagination>
          <Pagination className="pagination-no-border">
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item active>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Item>{5}</Pagination.Item>
            <Pagination.Last />
          </Pagination>
          <CodeMirror value={codePagnation} options={options} />
          <br />
          <br />
          <p>
            Besides the classic look, we also added the color{" "}
            <code>className</code>s to offer more customisation (
            <code>pagination-blue</code>, <code>pagination-green</code>,{" "}
            <code>pagination-orange</code>, <code>pagination-red</code>).
          </p>
          <Pagination className="pagination-blue">
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item active>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Item>{5}</Pagination.Item>
            <Pagination.Last />
          </Pagination>
          <br />
          <Pagination className="pagination-green">
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item active>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Item>{5}</Pagination.Item>
            <Pagination.Last />
          </Pagination>
          <br />
          <Pagination className="pagination-orange">
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item active>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Item>{5}</Pagination.Item>
            <Pagination.Last />
          </Pagination>
          <br />
          <Pagination className="pagination-red">
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item active>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Item>{5}</Pagination.Item>
            <Pagination.Last />
          </Pagination>
          <br />
          <br />
          <CodeMirror value={codePagnationColors} options={options} />
          <h4>Props</h4>
          <p>
            Please refer to{" "}
            <a
              href="https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/pagination/#pagination"
              target="_blank"
            >
              react-bootstrap documentation
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default PaginationSection;

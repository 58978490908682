/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

import { Card, Collapse } from "react-bootstrap";

const options = {
  mode: "jsx",
  readOnly: true,
};

const codeExamplePanels = `import React from "react";

// react-bootstrap components
import { Card, Collapse } from "react-bootstrap";

function Example() {
  const [
    multipleExpandablePanels,
    setMultipleExpandablePanels,
  ] = React.useState([]);
  const toggleMultipleExpandablePanels = (event, value) => {
    event.preventDefault();
    if (multipleExpandablePanels.includes(value)) {
      setMultipleExpandablePanels(
        multipleExpandablePanels.filter((prop) => prop !== value)
      );
    } else {
      setMultipleExpandablePanels([...multipleExpandablePanels, value]);
    }
  };
  return (
    <>
      <div className="accordions" id="accordion">
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <a
                data-toggle="collapse"
                aria-expanded={multipleExpandablePanels.includes(1)}
                href="#pablo"
                onClick={(e) => toggleMultipleExpandablePanels(e, 1)}
              >
                Collapse item 1 <b className="caret"></b>
              </a>
            </Card.Title>
          </Card.Header>
          <Collapse
            className="card-collapse"
            id="collapseOne"
            in={multipleExpandablePanels.includes(1)}
          >
            <Card.Body>
              Anim pariatur cliche reprehenderit, enim eiusmod high
              life accusamus terry richardson ad squid. 3 wolf moon
              officia aute, non cupidatat skateboard dolor brunch.
              Food truck quinoa nesciunt laborum eiusmod. Brunch 3
              wolf moon tempor, sunt aliqua put a bird on it squid
              single-origin coffee nulla assumenda shoreditch et.
              Nihil anim keffiyeh helvetica, craft beer labore wes
              anderson cred nesciunt sapiente ea proident. Ad vegan
              excepteur butcher vice lomo. Leggings occaecat craft
              beer farm-to-table, raw denim aesthetic synth nesciunt
              you probably haven't heard of them accusamus labore
              sustainable VHS.
            </Card.Body>
          </Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <a
                data-toggle="collapse"
                aria-expanded={multipleExpandablePanels.includes(2)}
                href="#pablo"
                onClick={(e) => toggleMultipleExpandablePanels(e, 2)}
              >
                Collapse item 2 <b className="caret"></b>
              </a>
            </Card.Title>
          </Card.Header>
          <Collapse
            className="card-collapse"
            id="collapseTwo"
            in={multipleExpandablePanels.includes(2)}
          >
            <Card.Body>
              Anim pariatur cliche reprehenderit, enim eiusmod high
              life accusamus terry richardson ad squid. 3 wolf moon
              officia aute, non cupidatat skateboard dolor brunch.
              Food truck quinoa nesciunt laborum eiusmod. Brunch 3
              wolf moon tempor, sunt aliqua put a bird on it squid
              single-origin coffee nulla assumenda shoreditch et.
              Nihil anim keffiyeh helvetica, craft beer labore wes
              anderson cred nesciunt sapiente ea proident. Ad vegan
              excepteur butcher vice lomo. Leggings occaecat craft
              beer farm-to-table, raw denim aesthetic synth nesciunt
              you probably haven't heard of them accusamus labore
              sustainable VHS.
            </Card.Body>
          </Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <a
                data-toggle="collapse"
                aria-expanded={multipleExpandablePanels.includes(3)}
                href="#pablo"
                onClick={(e) => toggleMultipleExpandablePanels(e, 3)}
              >
                Collapse item 3 <b className="caret"></b>
              </a>
            </Card.Title>
          </Card.Header>
          <Collapse
            className="card-collapse"
            id="collapseThree"
            in={multipleExpandablePanels.includes(3)}
          >
            <Card.Body>
              Anim pariatur cliche reprehenderit, enim eiusmod high
              life accusamus terry richardson ad squid. 3 wolf moon
              officia aute, non cupidatat skateboard dolor brunch.
              Food truck quinoa nesciunt laborum eiusmod. Brunch 3
              wolf moon tempor, sunt aliqua put a bird on it squid
              single-origin coffee nulla assumenda shoreditch et.
              Nihil anim keffiyeh helvetica, craft beer labore wes
              anderson cred nesciunt sapiente ea proident. Ad vegan
              excepteur butcher vice lomo. Leggings occaecat craft
              beer farm-to-table, raw denim aesthetic synth nesciunt
              you probably haven't heard of them accusamus labore
              sustainable VHS.
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    </>
  );
}

export default Example;
`;
const codeExampleAccordion = `import React from "react";

// react-bootstrap components
import { Card, Collapse } from "react-bootstrap";

function Panels() {
  const [collapsibleAccordion, setCollapsibleAccordion] = React.useState(-1);
  return (
    <>
      <div className="accordions" id="accordion">
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <a
                aria-expanded={collapsibleAccordion === 1}
                data-toggle="collapse"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  collapsibleAccordion === 1
                    ? setCollapsibleAccordion(-1)
                    : setCollapsibleAccordion(1);
                }}
              >
                Accodrion item 1 <b className="caret"></b>
              </a>
            </Card.Title>
          </Card.Header>
          <Collapse
            className="card-collapse"
            id="collapseOneHover"
            in={collapsibleAccordion === 1}
          >
            <Card.Body>
              Anim pariatur cliche reprehenderit, enim eiusmod high
              life accusamus terry richardson ad squid. 3 wolf moon
              officia aute, non cupidatat skateboard dolor brunch.
              Food truck quinoa nesciunt laborum eiusmod. Brunch 3
              wolf moon tempor, sunt aliqua put a bird on it squid
              single-origin coffee nulla assumenda shoreditch et.
              Nihil anim keffiyeh helvetica, craft beer labore wes
              anderson cred nesciunt sapiente ea proident. Ad vegan
              excepteur butcher vice lomo. Leggings occaecat craft
              beer farm-to-table, raw denim aesthetic synth nesciunt
              you probably haven't heard of them accusamus labore
              sustainable VHS.
            </Card.Body>
          </Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <a
                aria-expanded={collapsibleAccordion === 2}
                data-toggle="collapse"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  collapsibleAccordion === 2
                    ? setCollapsibleAccordion(-1)
                    : setCollapsibleAccordion(2);
                }}
              >
                Accodrion item 2 <b className="caret"></b>
              </a>
            </Card.Title>
          </Card.Header>
          <Collapse
            className="card-collapse"
            id="collapseTwoHover"
            in={collapsibleAccordion === 2}
          >
            <Card.Body>
              Anim pariatur cliche reprehenderit, enim eiusmod high
              life accusamus terry richardson ad squid. 3 wolf moon
              officia aute, non cupidatat skateboard dolor brunch.
              Food truck quinoa nesciunt laborum eiusmod. Brunch 3
              wolf moon tempor, sunt aliqua put a bird on it squid
              single-origin coffee nulla assumenda shoreditch et.
              Nihil anim keffiyeh helvetica, craft beer labore wes
              anderson cred nesciunt sapiente ea proident. Ad vegan
              excepteur butcher vice lomo. Leggings occaecat craft
              beer farm-to-table, raw denim aesthetic synth nesciunt
              you probably haven't heard of them accusamus labore
              sustainable VHS.
            </Card.Body>
          </Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <a
                data-toggle="collapse"
                href="#pablo"
                aria-expanded={collapsibleAccordion === 3}
                onClick={(e) => {
                  e.preventDefault();
                  collapsibleAccordion === 3
                    ? setCollapsibleAccordion(-1)
                    : setCollapsibleAccordion(3);
                }}
              >
                Accodrion item 3 <b className="caret"></b>
              </a>
            </Card.Title>
          </Card.Header>
          <Collapse
            className="card-collapse"
            id="collapseThreeHover"
            in={collapsibleAccordion === 3}
          >
            <Card.Body>
              Anim pariatur cliche reprehenderit, enim eiusmod high
              life accusamus terry richardson ad squid. 3 wolf moon
              officia aute, non cupidatat skateboard dolor brunch.
              Food truck quinoa nesciunt laborum eiusmod. Brunch 3
              wolf moon tempor, sunt aliqua put a bird on it squid
              single-origin coffee nulla assumenda shoreditch et.
              Nihil anim keffiyeh helvetica, craft beer labore wes
              anderson cred nesciunt sapiente ea proident. Ad vegan
              excepteur butcher vice lomo. Leggings occaecat craft
              beer farm-to-table, raw denim aesthetic synth nesciunt
              you probably haven't heard of them accusamus labore
              sustainable VHS.
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    </>
  );
}

export default Panels;
`;

function PanelsSection() {
  const [
    multipleExpandablePanels,
    setMultipleExpandablePanels,
  ] = React.useState([]);
  const [collapsibleAccordion, setCollapsibleAccordion] = React.useState(-1);
  const toggleMultipleExpandablePanels = (event, value) => {
    event.preventDefault();
    if (multipleExpandablePanels.includes(value)) {
      setMultipleExpandablePanels(
        multipleExpandablePanels.filter((prop) => prop !== value)
      );
    } else {
      setMultipleExpandablePanels([...multipleExpandablePanels, value]);
    }
  };
  return (
    <div className="tim-container">
      <div className="tim-row">
        <h2>Panels</h2>
        <legend />
        <h4>Example code</h4>
        <div className="accordions" id="accordion">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <a
                  data-toggle="collapse"
                  aria-expanded={multipleExpandablePanels.includes(1)}
                  href="#pablo"
                  onClick={(e) => toggleMultipleExpandablePanels(e, 1)}
                >
                  Collapse item 1 <b className="caret"></b>
                </a>
              </Card.Title>
            </Card.Header>
            <Collapse
              className="card-collapse"
              id="collapseOne"
              in={multipleExpandablePanels.includes(1)}
            >
              <Card.Body>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </Card.Body>
            </Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <a
                  data-toggle="collapse"
                  aria-expanded={multipleExpandablePanels.includes(2)}
                  href="#pablo"
                  onClick={(e) => toggleMultipleExpandablePanels(e, 2)}
                >
                  Collapse item 2 <b className="caret"></b>
                </a>
              </Card.Title>
            </Card.Header>
            <Collapse
              className="card-collapse"
              id="collapseTwo"
              in={multipleExpandablePanels.includes(2)}
            >
              <Card.Body>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </Card.Body>
            </Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <a
                  data-toggle="collapse"
                  aria-expanded={multipleExpandablePanels.includes(3)}
                  href="#pablo"
                  onClick={(e) => toggleMultipleExpandablePanels(e, 3)}
                >
                  Collapse item 3 <b className="caret"></b>
                </a>
              </Card.Title>
            </Card.Header>
            <Collapse
              className="card-collapse"
              id="collapseThree"
              in={multipleExpandablePanels.includes(3)}
            >
              <Card.Body>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </Card.Body>
            </Collapse>
          </Card>
        </div>
        <br />
        <CodeMirror value={codeExamplePanels} options={options} />
        <br />
        <h2>Accordion</h2>
        <legend />
        <h4>Example code</h4>
        <div className="accordions" id="accordion">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <a
                  aria-expanded={collapsibleAccordion === 1}
                  data-toggle="collapse"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    collapsibleAccordion === 1
                      ? setCollapsibleAccordion(-1)
                      : setCollapsibleAccordion(1);
                  }}
                >
                  Accodrion item 1 <b className="caret"></b>
                </a>
              </Card.Title>
            </Card.Header>
            <Collapse
              className="card-collapse"
              id="collapseOneHover"
              in={collapsibleAccordion === 1}
            >
              <Card.Body>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </Card.Body>
            </Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <a
                  aria-expanded={collapsibleAccordion === 2}
                  data-toggle="collapse"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    collapsibleAccordion === 2
                      ? setCollapsibleAccordion(-1)
                      : setCollapsibleAccordion(2);
                  }}
                >
                  Accodrion item 2 <b className="caret"></b>
                </a>
              </Card.Title>
            </Card.Header>
            <Collapse
              className="card-collapse"
              id="collapseTwoHover"
              in={collapsibleAccordion === 2}
            >
              <Card.Body>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </Card.Body>
            </Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <a
                  data-toggle="collapse"
                  href="#pablo"
                  aria-expanded={collapsibleAccordion === 3}
                  onClick={(e) => {
                    e.preventDefault();
                    collapsibleAccordion === 3
                      ? setCollapsibleAccordion(-1)
                      : setCollapsibleAccordion(3);
                  }}
                >
                  Accodrion item 3 <b className="caret"></b>
                </a>
              </Card.Title>
            </Card.Header>
            <Collapse
              className="card-collapse"
              id="collapseThreeHover"
              in={collapsibleAccordion === 3}
            >
              <Card.Body>
                Anim pariatur cliche reprehenderit, enim eiusmod high life
                accusamus terry richardson ad squid. 3 wolf moon officia aute,
                non cupidatat skateboard dolor brunch. Food truck quinoa
                nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
                wes anderson cred nesciunt sapiente ea proident. Ad vegan
                excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably
                haven't heard of them accusamus labore sustainable VHS.
              </Card.Body>
            </Collapse>
          </Card>
        </div>
        <br />
        <CodeMirror value={codeExampleAccordion} options={options} />
        <br />
      </div>
    </div>
  );
}

export default PanelsSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

import { Button, Form } from "react-bootstrap";

import img1 from "assets/img/blog-1.jpg";
import img2 from "assets/img/blog-2.jpg";

const options = {
  mode: "jsx",
  readOnly: true,
};

const codeExampleSimple = `import React from "react";

// react-bootstrap components
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";

function Example() {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Name</th>
            <th>Job Position</th>
            <th className="text-right">Salary</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">1</td>
            <td>Andrew Mike</td>
            <td>Develop</td>
            <td className="text-right">€ 99,225</td>
            <td className="td-actions text-right">
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-48903503">View Profile..</Tooltip>
                }
              >
                <Button
                  className="btn-link btn-xs"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="info"
                >
                  <i className="fas fa-user"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-981231696">Edit Profile..</Tooltip>
                }
              >
                <Button
                  className="btn-link btn-xs"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="success"
                >
                  <i className="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={<Tooltip id="tooltip-255158527">Remove..</Tooltip>}
              >
                <Button
                  className="btn-link btn-xs"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="danger"
                >
                  <i className="fas fa-times"></i>
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
          <tr>
            <td className="text-center">2</td>
            <td>John Doe</td>
            <td>Design</td>
            <td className="text-right">€ 89,241</td>
            <td className="td-actions text-right">
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-150479227">View Profile..</Tooltip>
                }
              >
                <Button
                  className="btn-link btn-xs"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="info"
                >
                  <i className="fas fa-user"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-292560270">Edit Profile..</Tooltip>
                }
              >
                <Button
                  className="btn-link btn-xs"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="success"
                >
                  <i className="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={<Tooltip id="tooltip-410038576">Remove..</Tooltip>}
              >
                <Button
                  className="btn-link btn-xs"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="danger"
                >
                  <i className="fas fa-times"></i>
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default Example;`;
const codeExampleStriped = `import React from "react";

// react-bootstrap components
import { Form, Table } from "react-bootstrap";

function Example() {
  return (
    <>
      <Table className="table-striped">
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Name</th>
            <th>Job Position</th>
            <th className="text-right">Salary</th>
            <th className="text-right">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center">1</td>
            <td>Andrew Mike</td>
            <td>Develop</td>
            <td className="text-right">€ 99,225</td>
            <td className="d-flex justify-content-end">
              <Form.Check
                type="switch"
                id="custom-switch-1"
                className="mb-1"
                defaultChecked
              />
            </td>
          </tr>
          <tr>
            <td className="text-center">2</td>
            <td>John Doe</td>
            <td>Design</td>
            <td className="text-right">€ 89,241</td>
            <td className="d-flex justify-content-end">
              <Form.Check type="switch" id="custom-switch-2" className="mb-1" />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default Example;
`;
const codeExampleBig = `import React from "react";

// react-bootstrap components
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";

function Example() {
  return (
    <>
      <Table className="table-bigboy">
        <thead>
          <tr>
            <th className="text-center">Thumb</th>
            <th>Blog Title</th>
            <th className="th-description">Description</th>
            <th className="text-right">Date</th>
            <th className="text-right">Views</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="img-container">
                <img
                  alt="..."
                  src={require("assets/img/blog-1.jpg").default}
                ></img>
              </div>
            </td>
            <td className="td-name">10 Things that all designers do</td>
            <td>
              Most beautiful agenda for the office, really nice paper and black
              cover. Most beautiful agenda for the office.
            </td>
            <td className="td-number">30/08/2016</td>
            <td className="td-number">1,225</td>
            <td className="td-actions">
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-618009180">View Post..</Tooltip>}
                placement="left"
              >
                <Button
                  className="btn-link btn-icon"
                  type="button"
                  variant="info"
                >
                  <i className="far fa-image"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-461494662">Edit Post..</Tooltip>}
                placement="left"
              >
                <Button
                  className="btn-link btn-icon"
                  type="button"
                  variant="success"
                >
                  <i className="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-408856985">Remove Post..</Tooltip>
                }
                placement="left"
              >
                <Button
                  className="btn-link btn-icon"
                  type="button"
                  variant="danger"
                >
                  <i className="fas fa-times"></i>
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
          <tr>
            <td>
              <div className="img-container">
                <img
                  alt="..."
                  src={require("assets/img/blog-2.jpg").default}
                ></img>
              </div>
            </td>
            <td className="td-name">Back to School Offer</td>
            <td>
              Design is not just what it looks like and feels like. Design is
              how it works.
            </td>
            <td className="td-number">17/07/2016</td>
            <td className="td-number">49,302</td>
            <td className="td-actions">
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-65578954">View Post..</Tooltip>}
                placement="left"
              >
                <Button
                  className="btn-link btn-icon"
                  type="button"
                  variant="info"
                >
                  <i className="far fa-image"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-38536367">Edit Post..</Tooltip>}
                placement="left"
              >
                <Button
                  className="btn-link btn-icon"
                  type="button"
                  variant="success"
                >
                  <i className="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-220404926">Remove Post..</Tooltip>
                }
                placement="left"
              >
                <Button
                  className="btn-link btn-icon"
                  type="button"
                  variant="danger"
                >
                  <i className="fas fa-times"></i>
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default Example;
`;
function TablesSection() {
  return (
    <div className="tim-container">
      <div className="tim-row">
        <h2>Tables</h2>
        <legend />
        <p>
          All Boostrap classes for tables are supported and improved. Besides
          the simple and striped tables, we added tables that have actions and
          table with switches. We have also created the Big Boy table, that can
          be used for content management systems or in the checkout process of
          an ecommerce. It offers extended functionality, like adding pictures,
          descriptions and actions.
        </p>
        <h3>Simple Table with Actions</h3>
        <h4>Example code</h4>
        <Table>
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Name</th>
              <th>Job Position</th>
              <th className="text-right">Salary</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">1</td>
              <td>Andrew Mike</td>
              <td>Develop</td>
              <td className="text-right">€ 99,225</td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={
                    <Tooltip id="tooltip-48903503">View Profile..</Tooltip>
                  }
                >
                  <Button
                    className="btn-link btn-xs"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    variant="info"
                  >
                    <i className="fas fa-user"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={
                    <Tooltip id="tooltip-981231696">Edit Profile..</Tooltip>
                  }
                >
                  <Button
                    className="btn-link btn-xs"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    variant="success"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={<Tooltip id="tooltip-255158527">Remove..</Tooltip>}
                >
                  <Button
                    className="btn-link btn-xs"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td className="text-center">2</td>
              <td>John Doe</td>
              <td>Design</td>
              <td className="text-right">€ 89,241</td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={
                    <Tooltip id="tooltip-150479227">View Profile..</Tooltip>
                  }
                >
                  <Button
                    className="btn-link btn-xs"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    variant="info"
                  >
                    <i className="fas fa-user"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={
                    <Tooltip id="tooltip-292560270">Edit Profile..</Tooltip>
                  }
                >
                  <Button
                    className="btn-link btn-xs"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    variant="success"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={<Tooltip id="tooltip-410038576">Remove..</Tooltip>}
                >
                  <Button
                    className="btn-link btn-xs"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <CodeMirror value={codeExampleSimple} options={options} />
        <h3>Striped Table with Switches</h3>
        <h4>Example code</h4>
        <Table className="table-striped">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Name</th>
              <th>Job Position</th>
              <th className="text-right">Salary</th>
              <th className="text-right">Active</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">1</td>
              <td>Andrew Mike</td>
              <td>Develop</td>
              <td className="text-right">€ 99,225</td>
              <td className="d-flex justify-content-end">
                <Form.Check
                  type="switch"
                  id="custom-switch-1"
                  className="mb-1"
                  defaultChecked
                />
              </td>
            </tr>
            <tr>
              <td className="text-center">2</td>
              <td>John Doe</td>
              <td>Design</td>
              <td className="text-right">€ 89,241</td>
              <td className="d-flex justify-content-end">
                <Form.Check
                  type="switch"
                  id="custom-switch-2"
                  className="mb-1"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <CodeMirror value={codeExampleStriped} options={options} />
        <br />
        <h3>Big Boy Table</h3>
        <h4>Example code</h4>
        <Table className="table-bigboy">
          <thead>
            <tr>
              <th className="text-center">Thumb</th>
              <th>Blog Title</th>
              <th className="th-description">Description</th>
              <th className="text-right">Date</th>
              <th className="text-right">Views</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="img-container">
                  <img
                    alt="..."
                    src={require("assets/img/blog-1.jpg").default}
                  ></img>
                </div>
              </td>
              <td className="td-name">10 Things that all designers do</td>
              <td>
                Most beautiful agenda for the office, really nice paper and
                black cover. Most beautiful agenda for the office.
              </td>
              <td className="td-number">30/08/2016</td>
              <td className="td-number">1,225</td>
              <td className="td-actions">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-618009180">View Post..</Tooltip>
                  }
                  placement="left"
                >
                  <Button
                    className="btn-link btn-icon"
                    type="button"
                    variant="info"
                  >
                    <i className="far fa-image"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-461494662">Edit Post..</Tooltip>
                  }
                  placement="left"
                >
                  <Button
                    className="btn-link btn-icon"
                    type="button"
                    variant="success"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-408856985">Remove Post..</Tooltip>
                  }
                  placement="left"
                >
                  <Button
                    className="btn-link btn-icon"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td>
                <div className="img-container">
                  <img
                    alt="..."
                    src={require("assets/img/blog-2.jpg").default}
                  ></img>
                </div>
              </td>
              <td className="td-name">Back to School Offer</td>
              <td>
                Design is not just what it looks like and feels like. Design is
                how it works.
              </td>
              <td className="td-number">17/07/2016</td>
              <td className="td-number">49,302</td>
              <td className="td-actions">
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-65578954">View Post..</Tooltip>}
                  placement="left"
                >
                  <Button
                    className="btn-link btn-icon"
                    type="button"
                    variant="info"
                  >
                    <i className="far fa-image"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-38536367">Edit Post..</Tooltip>}
                  placement="left"
                >
                  <Button
                    className="btn-link btn-icon"
                    type="button"
                    variant="success"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-220404926">Remove Post..</Tooltip>
                  }
                  placement="left"
                >
                  <Button
                    className="btn-link btn-icon"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <CodeMirror value={codeExampleBig} options={options} />
        <br />
        <h4>Props</h4>
        <p>
          For tables we have used mostly HTML, there is the <code>Table</code>{" "}
          react-bootstrap tag, to write less. You can find out more about this
          component{" "}
          <a
            href="https://5c507d49471426000887a6a7--react-bootstrap.netlify.com/components/table/"
            target="_blank"
          >
            here
          </a>
          .
        </p>
        <p>Beside these tables, there are normal tables as well.</p>
      </div>
    </div>
  );
}

export default TablesSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

// react-bootstrap components
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const options = {
  mode: "jsx",
  readOnly: true,
};

const codeExample = `import React from "react";

// react-bootstrap components
import {
  Button,
  Form,
  OverlayTrigger,
  Table,
  Tooltip
} from "react-bootstrap";

function Example() {
  return (
    <>
      <div className="table-full-width">
        <Table>
          <tbody>
            <tr>
              <td>
                <Form.Check className="mb-1 pl-0">
                  <Form.Check.Label>
                    <Form.Check.Input
                      defaultValue=""
                      type="checkbox"
                    ></Form.Check.Input>
                    <span className="form-check-sign"></span>
                  </Form.Check.Label>
                </Form.Check>
              </td>
              <td>
                Sign contract for "What are conference organizers
                afraid of?"
              </td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-688296980">
                      Edit Task..
                    </Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="info"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-202192706">Remove..</Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Check className="mb-1 pl-0">
                  <Form.Check.Label>
                    <Form.Check.Input
                      defaultChecked
                      defaultValue=""
                      type="checkbox"
                    ></Form.Check.Input>
                    <span className="form-check-sign"></span>
                  </Form.Check.Label>
                </Form.Check>
              </td>
              <td>
                Lines From Great Russian Literature? Or E-mails From
                My Boss?
              </td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-746544352">
                      Edit Task..
                    </Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="info"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-743037005">Remove..</Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Check className="mb-1 pl-0">
                  <Form.Check.Label>
                    <Form.Check.Input
                      defaultChecked
                      defaultValue=""
                      type="checkbox"
                    ></Form.Check.Input>
                    <span className="form-check-sign"></span>
                  </Form.Check.Label>
                </Form.Check>
              </td>
              <td>
                Flooded: One year later, assessing what was lost and
                what was found when a ravaging rain swept through
                metro Detroit
              </td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-855684210">
                      Edit Task..
                    </Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="info"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-242945902">Remove..</Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Check className="mb-1 pl-0">
                  <Form.Check.Label>
                    <Form.Check.Input
                      defaultChecked
                      type="checkbox"
                    ></Form.Check.Input>
                    <span className="form-check-sign"></span>
                  </Form.Check.Label>
                </Form.Check>
              </td>
              <td>
                Create 4 Invisible User Experiences you Never Knew
                About
              </td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-488557184">
                      Edit Task..
                    </Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="info"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-789597281">Remove..</Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Check className="mb-1 pl-0">
                  <Form.Check.Label>
                    <Form.Check.Input
                      defaultValue=""
                      type="checkbox"
                    ></Form.Check.Input>
                    <span className="form-check-sign"></span>
                  </Form.Check.Label>
                </Form.Check>
              </td>
              <td>Read "Following makes Medium better"</td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-521344137">
                      Edit Task..
                    </Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="info"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-934093947">Remove..</Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Check className="mb-1 pl-0">
                  <Form.Check.Label>
                    <Form.Check.Input
                      defaultChecked
                      defaultValue=""
                      type="checkbox"
                    ></Form.Check.Input>
                    <span className="form-check-sign"></span>
                  </Form.Check.Label>
                </Form.Check>
              </td>
              <td>Unfollow 5 enemies from twitter</td>
              <td className="td-actions text-right">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-97404283">
                      Edit Task..
                    </Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="info"
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-888894273">Remove..</Tooltip>
                  }
                  placement="top"
                >
                  <Button
                    className="btn-simple btn-link"
                    type="button"
                    variant="danger"
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Example;
`;
class TasksSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Tasks</h2>
          <legend />
          <h4>Example code</h4>
          <div className="table-full-width">
            <Table>
              <tbody>
                <tr>
                  <td>
                    <Form.Check className="mb-1 pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultValue=""
                          type="checkbox"
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>
                    Sign contract for "What are conference organizers afraid
                    of?"
                  </td>
                  <td className="td-actions text-right">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-688296980">Edit Task..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="info"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-202192706">Remove..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="danger"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Check className="mb-1 pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultChecked
                          defaultValue=""
                          type="checkbox"
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>
                    Lines From Great Russian Literature? Or E-mails From My
                    Boss?
                  </td>
                  <td className="td-actions text-right">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-746544352">Edit Task..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="info"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-743037005">Remove..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="danger"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Check className="mb-1 pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultChecked
                          defaultValue=""
                          type="checkbox"
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>
                    Flooded: One year later, assessing what was lost and what
                    was found when a ravaging rain swept through metro Detroit
                  </td>
                  <td className="td-actions text-right">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-855684210">Edit Task..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="info"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-242945902">Remove..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="danger"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Check className="mb-1 pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultChecked
                          type="checkbox"
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>
                    Create 4 Invisible User Experiences you Never Knew About
                  </td>
                  <td className="td-actions text-right">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-488557184">Edit Task..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="info"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-789597281">Remove..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="danger"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Check className="mb-1 pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultValue=""
                          type="checkbox"
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>Read "Following makes Medium better"</td>
                  <td className="td-actions text-right">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-521344137">Edit Task..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="info"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-934093947">Remove..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="danger"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Check className="mb-1 pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input
                          defaultChecked
                          defaultValue=""
                          type="checkbox"
                        ></Form.Check.Input>
                        <span className="form-check-sign"></span>
                      </Form.Check.Label>
                    </Form.Check>
                  </td>
                  <td>Unfollow 5 enemies from twitter</td>
                  <td className="td-actions text-right">
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-97404283">Edit Task..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="info"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-888894273">Remove..</Tooltip>
                      }
                      placement="top"
                    >
                      <Button
                        className="btn-simple btn-link"
                        type="button"
                        variant="danger"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <CodeMirror value={codeExample} options={options} />
        </div>
      </div>
    );
  }
}

export default TasksSection;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import CodeMirror from "react-codemirror";
import "codemirror/mode/jsx/jsx";

import avatar from "assets/img/default-avatar.png";

const options = {
  mode: "jsx",
  readOnly: true,
};
const codeExampleCard = `import React from "react";

// react-bootstrap components
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";

function Example() {
  return (
    <>
      <Row>
        <Col lg="3" sm="6">
          <Card>
            <Card.Header>
              <Card.Title>This is the title of the card</Card.Title>
              <p className="category">This is the category of the card</p>
            </Card.Header>
            <Card.Body>
              <p>Hey!</p>
              <p>
                We are some <code>p</code>s
              </p>
              <p>
                Wrapped inside a <code>div</code>
              </p>
              <p>And we are the content of the card</p>
            </Card.Body>
            <Card.Footer>
              <div className="legend">
                <div>
                  <p>This is the legend.</p>
                  <p>This is the second line of the legend</p>
                </div>
              </div>
              <hr />
              <div className="stats">
                <div>
                  <i className="fa fa-clock-o"></i> This is some stat with an icon
                </div>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Example;`;
const codeExampleUserCard = `import React from "react";

// react-bootstrap components
import { Button, Card, Row, Col } from "react-bootstrap";

function Example() {
  return (
    <>
      <Row>
        <Col md="4">
          <Card className="card-user">
            <Card.Header className="no-padding">
              <div className="card-image">
                <img
                  alt="..."
                  src={require("assets/img/full-screen-image-3.jpg").default}
                ></img>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="author">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="..."
                    className="avatar border-gray"
                    src={require("assets/img/default-avatar.png").default}
                  ></img>
                  <Card.Title as="h5">Tania Keatley</Card.Title>
                </a>
                <p className="card-description">michael24</p>
              </div>
              <p className="card-description text-center">
                Hey there! As you can see, <br></br>
                it is already looking great.
              </p>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="button-container text-center">
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-google-plus-square"></i>
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Example;
`;
const codeExampleStatsCard = `import React from "react";

// react-bootstrap components
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";

function Example() {
  return (
    <>
      <Row>
        <Col lg="3" sm="6">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-chart text-warning"></i>
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                    <p className="card-category">Number</p>
                    <Card.Title as="h4">150GB</Card.Title>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="stats">
                <i className="fas fa-redo mr-1"></i>
                Update Now
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Example;`;

class CardsSection extends Component {
  render() {
    return (
      <div className="tim-container">
        <div className="tim-row">
          <h2>Cards</h2>
          <legend />
          <p>
            We've created three types of cards, one is for simple use and can be
            modified with some props, one is for creating user cards, one is for
            creating stats cards.
          </p>
          <h3>Card</h3>
          <legend />
          <p>
            The following is just a simple example with some text, but you can
            see more examples in views of this demo (charts, tables, forms,
            calendar, icons, almost all of our demo pages use this kind of card
            - you can check them to understand better).
          </p>
          <h4>Example</h4>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Header>
                  <Card.Title>This is the title of the card</Card.Title>
                  <p className="category">This is the category of the card</p>
                </Card.Header>
                <Card.Body>
                  <p>Hey!</p>
                  <p>
                    We are some <code>p</code>s
                  </p>
                  <p>
                    Wrapped inside a <code>div</code>
                  </p>
                  <p>And we are the content of the card</p>
                </Card.Body>
                <Card.Footer>
                  <div className="legend">
                    <div>
                      <p>This is the legend.</p>
                      <p>This is the second line of the legend</p>
                    </div>
                  </div>
                  <hr />
                  <div className="stats">
                    <div>
                      <i className="fa fa-clock-o"></i> This is some stat with
                      an icon
                    </div>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <CodeMirror value={codeExampleCard} options={options} />
          <h3>User Card</h3>
          <legend />
          <h4>Example</h4>
          <Row>
            <Col md={4}>
              <Card className="card-user">
                <Card.Header className="no-padding">
                  <div className="card-image">
                    <img
                      alt="..."
                      src={
                        require("assets/img/full-screen-image-3.jpg").default
                      }
                    ></img>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={require("assets/img/default-avatar.png").default}
                      ></img>
                      <Card.Title as="h5">Tania Keatley</Card.Title>
                    </a>
                    <p className="card-description">michael24</p>
                  </div>
                  <p className="card-description text-center">
                    Hey there! As you can see, <br></br>
                    it is already looking great.
                  </p>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="button-container text-center">
                    <Button
                      className="btn-simple btn-icon"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      variant="link"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                    <Button
                      className="btn-simple btn-icon"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      variant="link"
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    <Button
                      className="btn-simple btn-icon"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      variant="link"
                    >
                      <i className="fab fa-google-plus-square"></i>
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <CodeMirror value={codeExampleUserCard} options={options} />
          <h3>Stats Card</h3>
          <legend />
          <h4>Example</h4>
          <Row>
            <Col lg={4} sm={8}>
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Number</p>
                        <Card.Title as="h4">150GB</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-redo mr-1"></i>
                    Update Now
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <CodeMirror value={codeExampleStatsCard} options={options} />
        </div>
        <h4>Props</h4>
        <p>
          You can refer to{" "}
          <a
            href="https://react-bootstrap.github.io/components/cards/#api"
            target="_blank"
          >
            react-bootstrap documentation
          </a>{" "}
          for more props.
        </p>
      </div>
    );
  }
}

export default CardsSection;
